import { Vector2 } from "three";
import Node from "../../../Classes/Node";
import {
    distanceRayToColumn,
    distanceRayToWallCorners,
    distanceRayToLink
} from '../../Utils/columnsCalculation';
import { Convert } from '../helpers';

const drawLine = (ctx, startX, startY, endX, endY, color) => {
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.stroke();
    ctx.closePath();
};

export const drawColumn = ({
   ctx,
   plan,
   zoom,
   offsetX,
   offsetY,
   column,
   activeObject,
   walls,
   filters,
   level,
}) => {
    if (!column) return;

    let multip1 = 1;
    let angleWall;
    let noSizeSide = ''; /* [top|right|bottom|left] */

    if (column.parentWallID > -1) {
        const wall = plan.bWalls[column.parentWallID];
        const mainLink = wall?.mainLink;
        if (mainLink) {
            const v = mainLink.a.clone().sub(mainLink.b);
            angleWall = v.angle();

            const angle = column.angle;

            column.noSizeSide = noSizeSide = noSizeSide = 'bottom';

            if (column.parentWallSide === 'right') {
                multip1 = -1;
            }
        } else {
            column.setParentWallID(-1);
        }
    } else {
        column.noSizeSide = null;
    }

    {
        const x1 = column.x - Math.ceil(column.width / 2);
        const y1 = column.y - Math.ceil(column.depth / 2);
        const x2 = x1 + column.width;
        const y2 = y1;
        const x3 = x2;
        const y3 = y2 + column.depth;
        const x4 = x1;
        const y4 = y3;

        const p1 = new Vector2(x1, y1);
        const p2 = new Vector2(x2, y2);
        const p3 = new Vector2(x3, y3);
        const p4 = new Vector2(x4, y4);

        column.p1 = p1.rotateAround(new Vector2(column.x, column.y), -column.angle);
        column.p2 = p2.rotateAround(new Vector2(column.x, column.y), -column.angle);
        column.p3 = p3.rotateAround(new Vector2(column.x, column.y), -column.angle);
        column.p4 = p4.rotateAround(new Vector2(column.x, column.y), -column.angle);

        const p1PX = Convert.toPixel(new Node(p1.x, p1.y), ctx, zoom, offsetX, offsetY);
        const p2PX = Convert.toPixel(new Node(p2.x, p2.y), ctx, zoom, offsetX, offsetY);
        const p3PX = Convert.toPixel(new Node(p3.x, p3.y), ctx, zoom, offsetX, offsetY);
        const p4PX = Convert.toPixel(new Node(p4.x, p4.y), ctx, zoom, offsetX, offsetY);

        const isActive = activeObject && activeObject.isColumn && activeObject === column;

        if (isActive) {
            ctx.fillStyle = '#59DA28';

            ctx.save();

            ctx.lineWidth = 8;

            drawLine(ctx, p1PX.x, p1PX.y, p2PX.x, p2PX.y, '#7e5c52');
            drawLine(ctx, p2PX.x, p2PX.y, p3PX.x, p3PX.y, '#F56600');
            drawLine(ctx, p3PX.x, p3PX.y, p4PX.x, p4PX.y, '#e5c100');
            drawLine(ctx, p4PX.x, p4PX.y, p1PX.x, p1PX.y, '#006fff');

            ctx.restore();
        } else {
            if (column.type !== level) {
                ctx.fillStyle = '#bdbdbd';
                ctx.save();
                ctx.setLineDash([10, 10]);
                ctx.strokeStyle = '#000000';
                ctx.stroke();
            } else {
                ctx.fillStyle = '#000000';
            }
        }

        ctx.beginPath();
        ctx.moveTo(p1PX.x, p1PX.y);
        ctx.lineTo(p2PX.x, p2PX.y);
        ctx.lineTo(p3PX.x, p3PX.y);
        ctx.lineTo(p4PX.x, p4PX.y);
        ctx.lineTo(p1PX.x, p1PX.y);
        ctx.closePath();
        ctx.fill();

        if (column.type !== level) {
            ctx.stroke();
            ctx.restore();
        }

        if (isActive) {
            ctx.save();
            ctx.lineWidth = 1;
            ctx.setLineDash([10, 5]);

            const fixedLength = 200 * zoom;

            const startX = (p2PX.x + p3PX.x) / 2;
            const startY = (p2PX.y + p3PX.y) / 2;
            const endX = (p4PX.x + p1PX.x) / 2;
            const endY = (p4PX.y + p1PX.y) / 2;

            const dx = endX - startX;
            const dy = endY - startY;
            const length = Math.sqrt(dx * dx + dy * dy);

            const ux = dx / length;
            const uy = dy / length;

            const newStartX = startX - ux * fixedLength;
            const newStartY = startY - uy * fixedLength;
            const newEndX = endX + ux * fixedLength;
            const newEndY = endY + uy * fixedLength;

            drawLine(ctx, newStartX, newStartY, newEndX, newEndY, '#000');
            ctx.restore();
        }
    }

    const point = Convert.toPixel(new Node(column.x, column.y), ctx, zoom, offsetX, offsetY);

    const x1 = -Math.ceil(column.width * zoom / 2);
    const y1 = -Math.ceil(column.depth * zoom / 2);
    const x2 = x1 + column.width * zoom;
    const y2 = y1;
    const x3 = x2;
    const y3 = y2 + column.depth * zoom;
    const x4 = x1;
    const y4 = y3;

    ctx.lineWidth = 1
    ctx.font = '10px Arial';

    ctx.translate(point.x, point.y);
    ctx.rotate(-column.angle);

    if (activeObject && activeObject.isColumn && activeObject === column) {
        const r = Math.min(12, 56 / zoom);
        const pivot = new Vector2(x1 - r, y1 - r);

        ctx.fillStyle = '#222222'

        ctx.strokeStyle = '#ffffff';
        ctx.lineWidth = 1;

        if (noSizeSide === '' && activeObject?.showSizeButtons) {
            ctx.beginPath();
            ctx.arc(pivot.x, pivot.y, r, 0, 2 * Math.PI);
            ctx.fill();
            ctx.stroke();

            if (plan?.icons?.rotate) ctx.drawImage(plan.icons.rotate, pivot.x - r / 2, pivot.y - r / 2, r, r);
        }

        let aX, aY;
        if ((noSizeSide === '' || noSizeSide === 'bottom') && activeObject?.showSizeButtons) {
            aX = 0;
            aY = -column.depth * zoom / 2 - r * 1.5;
            ctx.beginPath();
            ctx.arc(aX, aY, r, 0, 2 * Math.PI);
            ctx.fill();
            ctx.stroke();

            if (plan?.icons?.atb) ctx.drawImage(plan.icons.atb, aX - r / 2, aY - r / 2, r, r);
        }
        if ((noSizeSide === '' || noSizeSide === 'top') && activeObject?.showSizeButtons) {
            aX = 0;
            aY = column.depth * zoom / 2 + r * 1.5;
            ctx.beginPath();
            ctx.arc(aX, aY, r, 0, 2 * Math.PI);
            ctx.fill();
            ctx.stroke();

            if (plan?.icons?.atb) ctx.drawImage(plan.icons.atb, aX - r / 2, aY - r / 2, r, r);
        }
        if (activeObject?.showSizeButtons) {
            aX = column.width * zoom / 2 + r * 1.5;
            aY = 0;
            ctx.beginPath();
            ctx.arc(aX, aY, r, 0, 2 * Math.PI);
            ctx.fill();
            ctx.stroke();

            if (plan?.icons?.alr) ctx.drawImage(plan.icons.alr, aX - r / 2, aY - r / 2, r, r);
        }
        if (activeObject?.showSizeButtons) {
            aX = -column.width * zoom / 2 - r * 1.5;
            aY = 0;
            ctx.beginPath();
            ctx.arc(aX, aY, r, 0, 2 * Math.PI);
            ctx.fill();
            ctx.stroke();

            if (plan?.icons?.alr) ctx.drawImage(plan.icons.alr, aX - r / 2, aY - r / 2, r, r);
        }

    }

    ctx.rotate(column.angle);
    ctx.translate(-point.x, -point.y);

    const location = { a: null, b: null, right: null, left: null, top: null, bottom: null };

    if (column.parentWallID > -1 && filters.ruler) {
        let d;
        let origin = new Vector2();
        let direction = new Vector2();
        let angle = column.angle;
        const zeroVector = new Vector2(0, 0);
        const offsetValue = noSizeSide === 'top' ? -1 : 1;
        column.noSizeSide = noSizeSide;

        const isReverse = (column.parentWallSide === 'left' && noSizeSide === 'top')
            || (column.parentWallSide === 'right' && noSizeSide === 'bottom');

        d = Infinity;

        origin = new Vector2(column.x - (multip1 * (column.width / 2)), column.y + offsetValue * (column.depth / 2 - 8));
        origin.rotateAround(new Vector2(column.x, column.y), -angle);
        direction.x = -1 * multip1;
        direction.y = 0;
        direction.rotateAround(zeroVector, -angle);

        if (column.parentWallID > -1) {
            let _d = distanceRayToWallCorners(origin, direction, walls[column.parentWallID], 'LEFT', column.parentWallSide);
            if (_d < d) {
                d = _d;
                if (!column.isFixed) column.height = walls[column.parentWallID].mainLink.height;
                column.maxHeight = walls[column.parentWallID].mainLink.height;
            }

            isReverse ? location.len1 = Math.round(_d) : location.len2 = Math.round(_d);

            walls[column.parentWallID][column.parentWallSide + 'Cols'].forEach((el) => {
                const clmn = plan.columns[el];
                if (clmn !== column) {
                    let _d = distanceRayToColumn(origin, direction, clmn);
                    if (_d < d) d = _d;
                }
            });
        }

        ctx.translate(point.x, point.y);
        ctx.rotate(-column.angle);

        if (d !== Infinity && Math.abs(d) > 1e-5) {
            d = Math.round(d);
            isReverse ? location.a = d : location.b = d;

            const currentWall = walls?.[column?.parentWallID];
            if (currentWall?.mainLink?.lrBuild === column.parentWallSide) {
                const tw = ctx.measureText(d.toString()).width + 8;
                ctx.translate((multip1 * -d / 2 - (multip1 * (column.width / 2))) * zoom, offsetValue * (column.depth / 2) * zoom - 8 * offsetValue);
                ctx.strokeStyle = '#FFB800';
                ctx.fillStyle = '#FFB800';
                ctx.beginPath();
                ctx.moveTo(-d * zoom / 2, 0);
                ctx.lineTo(d * zoom / 2, 0);
                ctx.stroke();
                ctx.strokeStyle = '#FFB800';
                ctx.fillStyle = '#FFB800';
                ctx.fillRect(-tw / 2, -8, tw, 16);
                ctx.strokeStyle = '#FFF';
                ctx.fillStyle = '#FFF';
                ctx.fillText(d, 0, 0);
                ctx.translate((multip1 * d / 2 + (multip1 * (column.width / 2))) * zoom, -offsetValue * (column.depth / 2) * zoom + 8 * offsetValue);
            }
        } else {
            isReverse ? location.a = null : location.b = null;
            isReverse ? location.len1 = null : location.len2 = null;
        }

        ctx.rotate(column.angle);
        ctx.translate(-point.x, -point.y);

        d = Infinity;
        origin = new Vector2(column.x + (multip1 * (column.width / 2)), column.y + offsetValue * (column.depth / 2 - 8));
        origin.rotateAround(new Vector2(column.x, column.y), -angle);
        direction.x = 1 * multip1;
        direction.y = 0;
        direction.rotateAround(zeroVector, -angle);

        if (column.parentWallID > -1) {
            let _d = distanceRayToWallCorners(origin, direction, walls[column.parentWallID], 'RIGHT', column.parentWallSide);
            if (_d < d) {
                d = _d;
                if (!column.isFixed) column.height = walls[column.parentWallID].mainLink.height;
                column.maxHeight = walls[column.parentWallID].mainLink.height;
            }

            isReverse ? location.len2 = Math.round(_d) : location.len1 = Math.round(_d);

            walls[column.parentWallID][column.parentWallSide + 'Cols'].forEach(el => {
                const clmn = plan.columns[el];
                if (clmn !== column && undefined !== clmn) {
                    let _d = distanceRayToColumn(origin, direction, clmn);
                    if (_d < d) d = _d;
                }
            });
        }

        ctx.translate(point.x, point.y);
        ctx.rotate(-column.angle);

        if (d !== Infinity && Math.abs(d) > 1e-5) {
            d = Math.round(d);
            isReverse ? location.b = d : location.a = d;

            const currentWall = walls?.[column?.parentWallID];
            if (currentWall?.mainLink?.lrBuild === column.parentWallSide) {
                const tw = ctx.measureText(d.toString()).width + 8;
                ctx.translate((multip1 * d / 2 + (multip1 * (column.width / 2))) * zoom, offsetValue * (column.depth / 2) * zoom - 8 * offsetValue);
                ctx.strokeStyle = '#FFB800';
                ctx.fillStyle = '#FFB800';
                ctx.beginPath();
                ctx.moveTo(-d * zoom / 2, 0);
                ctx.lineTo(d * zoom / 2, 0);
                ctx.stroke();
                ctx.strokeStyle = '#FFB800';
                ctx.fillStyle = '#FFB800';
                ctx.fillRect(-tw / 2, -8 - 1, tw, 16);
                ctx.strokeRect(-tw / 2, -8 - 1, tw, 16);
                ctx.strokeStyle = '#FFF';
                ctx.fillStyle = '#FFF';
                ctx.fillText(d, 0, 0);
                ctx.translate((multip1 * -d / 2 - (multip1 * (column.width / 2))) * zoom, offsetValue * -(column.depth / 2) * zoom + 8 * offsetValue);
            }
        } else {
            isReverse ? location.b = null : location.a = null;
            isReverse ? location.len2 = null : location.len1 = null;
        }

        ctx.rotate(column.angle);
        ctx.translate(-point.x, -point.y);

    } else if (
        activeObject
        && activeObject.isColumn
        && activeObject === column
        && !activeObject?.showSizeButtons
    ) {
        ctx.translate(point.x, point.y);
        ctx.rotate(-column.angle);

        let d;
        let origin = new Vector2();
        let direction = new Vector2();
        let angle = column.angle;
        const zeroVector = new Vector2(0, 0);

        d = Infinity;
        origin = new Vector2(column.x, column.y - (column.depth / 2));
        origin.rotateAround(new Vector2(column.x, column.y), -angle);
        direction.x = 0;
        direction.y = -1;
        direction.rotateAround(zeroVector, -angle);

        walls.map((w) => {
            let _d = distanceRayToLink(origin, direction, w);
            if (_d < d) {
                d = _d;
                if (!column.isFixed) column.height = w.mainLink.height;
                column.maxHeight = w.mainLink.height;
            }
        });

        plan.columns.map((clmn) => {
            if (clmn !== column) {
                let _d = distanceRayToColumn(origin, direction, clmn);
                if (_d < d) d = _d;
            }
        });

        if (d !== Infinity && d > 0) {
            d = Math.round(d);
            location.top = d;
            const tw = ctx.measureText(d.toString()).width + 8;
            ctx.translate(0, (-d / 2 - (column.depth / 2)) * zoom);
            ctx.rotate(-Math.PI / 2);
            ctx.strokeStyle = "#7e5c52";
            ctx.fillStyle = "#7e5c52";
            ctx.beginPath();
            ctx.moveTo(-d * zoom / 2, 0);
            ctx.lineTo(d * zoom / 2, 0);
            ctx.stroke();
            ctx.strokeStyle = "#7e5c52";
            ctx.fillStyle = "#7e5c52";
            ctx.fillRect(-tw / 2, -8, tw, 16);
            ctx.fillStyle = "#FFF";
            ctx.fillText(d, 0, 0);
            ctx.rotate(Math.PI / 2);
            ctx.translate(0, (d / 2 + (column.depth / 2)) * zoom);
        } else {
            location.top = null;
        }

        d = Infinity;
        origin = new Vector2(column.x, column.y + (column.depth / 2));
        origin.rotateAround(new Vector2(column.x, column.y), -angle);
        direction.x = 0;
        direction.y = 1;
        direction.rotateAround(zeroVector, -angle);

        walls.map((w) => {
            let _d = distanceRayToLink(origin, direction, w);
            if (_d < d) {
                d = _d;
                if (!column.isFixed) column.height = w.mainLink.height;
                column.maxHeight = w.mainLink.height;
            }
        });

        plan.columns.map((clmn) => {
            if (clmn !== column) {
                let _d = distanceRayToColumn(origin, direction, clmn);
                if (_d < d) d = _d;
            }
        });

        if (d !== Infinity && d > 0) {
            d = Math.round(d);
            location.bottom = d;
            const tw = ctx.measureText(d.toString()).width + 8;
            ctx.translate(0, (d / 2 + (column.depth / 2)) * zoom);
            ctx.rotate(-Math.PI / 2);
            ctx.strokeStyle = "#FFB800";
            ctx.fillStyle = "#FFB800";
            ctx.beginPath();
            ctx.moveTo(-d * zoom / 2, 0);
            ctx.lineTo(d * zoom / 2, 0);
            ctx.stroke();
            ctx.strokeStyle = "#FFB800";
            ctx.fillStyle = "#FFB800";
            ctx.fillRect(-tw / 2, -8, tw, 16);
            ctx.fillStyle = '#FFF';
            ctx.fillText(d, 0, 0);
            ctx.rotate(Math.PI / 2);
            ctx.translate(0, (-d / 2 - (column.depth / 2)) * zoom);
        } else {
            location.bottom = null;
        }

        d = Infinity;
        origin = new Vector2(column.x - (column.width / 2), column.y);
        origin.rotateAround(new Vector2(column.x, column.y), -angle);
        direction.x = -1;
        direction.y = 0;
        direction.rotateAround(zeroVector, -angle);

        walls.map((w) => {
            let _d = distanceRayToLink(origin, direction, w);
            if (_d < d) {
                d = _d;
                if (!column.isFixed) column.height = w.mainLink.height;
                column.maxHeight = w.mainLink.height;
            }
        });
        plan.columns.map((clmn) => {
            if (clmn !== column) {
                let _d = distanceRayToColumn(origin, direction, clmn);
                if (_d < d) d = _d;
            }
        });

        if (d !== Infinity && d > 0) {
            d = Math.round(d);
            location.left = d;
            const tw = ctx.measureText(d.toString()).width + 8;
            ctx.translate((-d / 2 - (column.width / 2)) * zoom, 0);
            ctx.strokeStyle = "#6082B6";
            ctx.fillStyle = "#6082B6";
            ctx.beginPath();
            ctx.moveTo(-d * zoom / 2, 0);
            ctx.lineTo(d * zoom / 2, 0);
            ctx.stroke();
            ctx.strokeStyle = "#6082B6";
            ctx.fillStyle = "#6082B6";
            ctx.fillRect(-tw / 2, -8, tw, 16);
            ctx.fillStyle = '#FFF';
            ctx.fillText(d, 0, 0);
            ctx.translate((d / 2 + (column.width / 2)) * zoom, 0);
        } else {
            location.left = null;
        }

        d = Infinity;
        origin = new Vector2(column.x + (column.width / 2), column.y);
        origin.rotateAround(new Vector2(column.x, column.y), -angle);
        direction.x = 1;
        direction.y = 0;
        direction.rotateAround(zeroVector, -angle);

        walls.map((w) => {
            let _d = distanceRayToLink(origin, direction, w);
            if (_d < d) {
                d = _d;
                if (!column.isFixed) column.height = w.mainLink.height;
                column.maxHeight = w.mainLink.height;
            }
        });
        plan.columns.map((clmn) => {
            if (clmn !== column) {
                let _d = distanceRayToColumn(origin, direction, clmn);
                if (_d < d) d = _d;
            }
        });
        if (d !== Infinity && d > 0) {
            d = Math.round(d);
            location.right = d;
            const tw = ctx.measureText(d.toString()).width + 8;
            ctx.translate((d / 2 + (column.width / 2)) * zoom, 0);
            ctx.strokeStyle = "#F56600";
            ctx.fillStyle = "#F56600";
            ctx.beginPath();
            ctx.moveTo(-d * zoom / 2, 0);
            ctx.lineTo(d * zoom / 2, 0);
            ctx.stroke();
            ctx.strokeStyle = "#F56600";
            ctx.fillStyle = "#F56600";
            ctx.fillRect(-tw / 2, -8, tw, 16);
            ctx.fillStyle = '#FFF';
            ctx.fillText(d, 0, 0);
            ctx.translate((-d / 2 - (column.width / 2)) * zoom, 0);
        } else {
            location.right = null;
        }

        ctx.rotate(column.angle);
        ctx.translate(-point.x, -point.y);
    }

    if (
        location.a === null
        && location.b === null
        && location.right === null
        && location.left === null
        && location.top === null
        && location.bottom === null
    ) {
        column.location = null;
    } else {
        column.location = location;
    }

    if (filters.ruler) {
        ctx.translate(point.x, point.y);
        ctx.rotate(-column.angle);

        const length1 = column.width;
        const length2 = column.depth;

        const txWidth = ctx.measureText(column.width).width + 8;
        const tyWidth = ctx.measureText(column.depth ).width + 8;

        if (noSizeSide !== 'top') {
            const _a = new Node(x1, y1);
            const _b = new Node(x2, y2);
            const v = _b.clone().sub(_a);
            const c = _b.clone().add(_a).multiplyScalar(0.5);
            const a = v.angle();
            const len = v.length();
            let halfH = -12
            ctx.translate(c.x, c.y);
            ctx.rotate(a);
            ctx.strokeStyle = '#FFB800';

            if (column.width * zoom > txWidth + 10) {
                ctx.beginPath();
                ctx.moveTo(-len / 2, -halfH - 17);
                ctx.lineTo(-len / 2, -halfH - 23);

                ctx.moveTo(len / 2, -halfH - 17);
                ctx.lineTo(len / 2, -halfH - 23);

                ctx.moveTo(-len / 2, -halfH - 20);
                ctx.lineTo(len / 2, -halfH - 20);
                ctx.stroke();
            }

            halfH = 0

            ctx.translate(0, -8 - halfH);
            ctx.strokeStyle = ctx.fillStyle = '#FFB800'

            ctx.fillRect(-txWidth / 2, -8 - 1, txWidth, 16);
            ctx.strokeRect(-txWidth / 2, -8 - 1, txWidth, 16);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(Math.PI);
            ctx.fillStyle = '#FFF';
            ctx.fillText(length1, 0, 0);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(-Math.PI);
            ctx.translate(0, 8 + halfH);

            ctx.rotate(-a);
            ctx.translate(-c.x, -c.y);
        }

        if (noSizeSide !== 'bottom') {
            const _a = new Node(x3, y3);
            const _b = new Node(x4, y4);
            const v = _b.clone().sub(_a);
            const c = _b.clone().add(_a).multiplyScalar(0.5);
            const a = v.angle();
            const len = v.length();
            let halfH = -12
            ctx.translate(c.x, c.y);
            ctx.rotate(a);
            ctx.strokeStyle = '#FFB800';

            if (column.width * zoom > txWidth + 10) {
                ctx.beginPath();
                ctx.moveTo(-len / 2, -halfH - 17);
                ctx.lineTo(-len / 2, -halfH - 23);

                ctx.moveTo(len / 2, -halfH - 17);
                ctx.lineTo(len / 2, -halfH - 23);

                ctx.moveTo(-len / 2, -halfH - 20);
                ctx.lineTo(len / 2, -halfH - 20);
                ctx.stroke();
            }

            halfH = 0

            ctx.translate(0, -8 - halfH);
            ctx.strokeStyle = ctx.fillStyle = '#FFB800'

            ctx.fillRect(-txWidth / 2, -8 - 1, txWidth, 16);
            ctx.strokeRect(-txWidth / 2, -8 - 1, txWidth, 16);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(Math.PI);
            ctx.fillStyle = '#FFF';
            ctx.fillText(length1, 0, 0);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(-Math.PI);
            ctx.translate(0, 8 + halfH);

            ctx.rotate(-a);
            ctx.translate(-c.x, -c.y);
        }

        {
            const _a = new Node(x2, y2);
            const _b = new Node(x3, y3);
            const v = _b.clone().sub(_a);
            const c = _b.clone().add(_a).multiplyScalar(0.5);
            const a = v.angle();
            const len = v.length();
            let halfH = -12
            ctx.translate(c.x, c.y);
            ctx.rotate(a);
            ctx.strokeStyle = '#FFB800';

            if (column.depth * zoom > tyWidth + 10) {
                ctx.beginPath();
                ctx.moveTo(-len / 2, -halfH - 17);
                ctx.lineTo(-len / 2, -halfH - 23);

                ctx.moveTo(len / 2, -halfH - 17);
                ctx.lineTo(len / 2, -halfH - 23);

                ctx.moveTo(-len / 2, -halfH - 20);
                ctx.lineTo(len / 2, -halfH - 20);
                ctx.stroke();
            }

            halfH = 0

            ctx.translate(0, -8 - halfH);
            ctx.strokeStyle = ctx.fillStyle = '#FFB800'

            ctx.fillRect(-tyWidth / 2, -8 - 1, tyWidth, 16);
            ctx.strokeRect(-tyWidth / 2, -8 - 1, tyWidth, 16);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(Math.PI);
            ctx.fillStyle = '#FFF';
            ctx.fillText(length2, 0, 0);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(-Math.PI);
            ctx.translate(0, 8 + halfH);

            ctx.rotate(-a);
            ctx.translate(-c.x, -c.y);
        }

        {
            const _a = new Node(x4, y4);
            const _b = new Node(x1, y1);
            const v = _b.clone().sub(_a);
            const c = _b.clone().add(_a).multiplyScalar(0.5);
            const a = v.angle();
            const len = v.length();
            let halfH = -12
            ctx.translate(c.x, c.y);
            ctx.rotate(a);
            ctx.strokeStyle = '#FFB800';

            if (column.depth * zoom > tyWidth + 10) {
                ctx.beginPath();
                ctx.moveTo(-len / 2, -halfH - 17);
                ctx.lineTo(-len / 2, -halfH - 23);

                ctx.moveTo(len / 2, -halfH - 17);
                ctx.lineTo(len / 2, -halfH - 23);

                ctx.moveTo(-len / 2, -halfH - 20);
                ctx.lineTo(len / 2, -halfH - 20);
                ctx.stroke();
            }

            halfH = 0

            ctx.translate(0, -8 - halfH);
            ctx.strokeStyle = ctx.fillStyle = '#FFB800'

            ctx.fillRect(-tyWidth / 2, -8 - 1, tyWidth, 16);
            ctx.strokeRect(-tyWidth / 2, -8 - 1, tyWidth, 16);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(Math.PI);
            ctx.fillStyle = '#FFF';
            ctx.fillText(length2, 0, 0);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(-Math.PI);
            ctx.translate(0, 8 + halfH);

            ctx.rotate(-a);
            ctx.translate(-c.x, -c.y);
        }

        ctx.rotate(column.angle);
        ctx.translate(-point.x, -point.y);
    }
}
