import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { useDispatch, useSelector } from 'react-redux';
import { getIntersection, getIntersectionLongLines, getMesh, polyPoint } from '../Helpers/functions';
import Node from '../Classes/Node'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { resizeDoor, resizeWindow } from '../Helpers/GeometryAlgoritm';
import { Vector2 } from 'three/build/three.min';
import { ColladaExporter } from 'three/examples/jsm/exporters/ColladaExporter';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter';
import { DragControls } from '../Classes/DragControls';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls'
import { actionsState as projectState } from '../Redux/project';
import { sendGetCollada, sendGetGLTF, sendGetHugeImage, sendGetOBJ, sendRedrawEvent } from '../Helpers/Events';
import FloorInfo from './FloorInfo';
import ModuleInfo3D from './ModuleInfo3D';
import BWallInfo from './BWallInfo';
import Link from '../Classes/Link';

import { subtractHoleOnWall, transformObjectOnWall } from './ThreeScene/helpers'
import * as constModels from './ThreeScene/constantsModels';
import { setSide, setSideEdging } from './ThreeScene/utils';

const isEqualPoints = (a, b) => {
    if (!a || !b) return false;
    return Math.abs(a.x - b.x) < 0.5 && Math.abs(a.y - b.y) < 0.5;
}

const ThreeScene = ({ plan }) => {
    const canvas = useRef(null);
    const dispatch = useDispatch();

    const modules = useSelector(store => store.modules.modules);
    const filters = useSelector(state => state.project.filters);
    const hugeImage = useSelector(state => state.project.hugeImage);
    const getFileType = useSelector(state => state.project.getFileType);
    const tokenROLE = useSelector(store => store.project.saveResult.tokenROLE);
    const cameraMode = useSelector(store => store.project.cameraMode);
    const tool = useSelector(state => state.project.tool);

    const [activeObject, setActiveObject] = useState(null);

    const allModules = useSelector(store => store.modules.allModules);

    useEffect(() => {
        plan.bWalls.forEach(wall => wall.showModule = true);
        sendRedrawEvent(document.querySelector('#scene'));
    }, [filters.walls, filters.transparent])


    useEffect(() => {
        let centerModule = null;
        let enableSelection = true;

        let windowModel = null;
        let doorModel = null;
        let electricSocketModel = null;
        let switchModel = null;
        let heatingBatteryModel = null;
        let electricPanelModel = null;

        let pointer = null;
        let controls = {};
        let activeObject = null;
        let INTERSECTED;
        let camera, scene, mouse = null,
            dragControls, orbitControls, ambientLight, spotLight,
            renderer, raycaster, composer, rp, effectFXAA, saoPass, clock,
            mouseIsOut = false, vnh;
        let player = {
            height: 1.6,
            turnSpeed: .05,
            speed: .1,
            gravity: .01,
            velocity: 0
        };

        raycaster = new THREE.Raycaster();

        const onResize = () => {
            const width = canvas.current.clientWidth;
            const height = canvas.current.clientHeight;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
        }

        window.addEventListener("resize", onResize);

        const generateFloor = (__cycles, __walls) => {
            // console.log('generateFloor',__cycles);
            const result = new THREE.Group();
            result.userData.type = "floor";
            result.name = 'Floor';

            __cycles.map((polygon) => {
                if (!polygon.showModule) return

                if (polygon.isFigure) { // Фигуры

                    const height = polygon.height / 1000;
                    const heightFromFloor = polygon.heightFromFloor / 1000;

                    const shape = new THREE.Shape();
                    let first = true;
                    let prev = polygon.points[polygon.points.length - 1];
                    polygon.points.forEach(point => {

                        let isArc = false
                        let arcInverse
                        __walls.map((wall, index) => {
                            if (wall.isArc) {
                                const node_x = Math.round(point.x)
                                const node_y = Math.round(point.y)
                                const prev_x = Math.round(prev.x)
                                const prev_y = Math.round(prev.y)
                                const a_x = Math.round(wall.a.x)
                                const a_y = Math.round(wall.a.y)
                                const b_x = Math.round(wall.b.x)
                                const b_y = Math.round(wall.b.y)
                                if (
                                    ((node_x === a_x && node_y === a_y) || (node_x === b_x && node_y === b_y)) &&
                                    ((prev_x === a_x && prev_y === a_y) || (prev_x === b_x && prev_y === b_y))
                                ) {
                                    isArc = true
                                    arcInverse = wall.arcInverse
                                }
                            }
                        })


                        if (first) shape.moveTo(prev.x / 1000, prev.y / 1000);

                        if (isArc) {

                            const wallA = prev.clone().add(point.clone().sub(prev).multiplyScalar(.5));
                            const wallB = prev

                            let length1 = (Math.ceil((new Vector2(wallA.x, wallA.y))
                                .distanceTo(new Vector2(wallB.x, wallB.y)))).toString()

                            const v = wallA.clone().sub(wallB)
                            const __v = v.clone().rotateAround(new Vector2(0, 0), 90 * (Math.PI / 180)).setLength(length1)
                            const grA = { x: __v.x + wallA.x, y: __v.y + wallA.y }

                            const __v2 = v.clone().rotateAround(new Vector2(0, 0), -90 * (Math.PI / 180)).setLength(length1)
                            const grB = { x: __v2.x + wallA.x, y: __v2.y + wallA.y }

                            const pointR = (!arcInverse) ? grB : grA
                            shape.bezierCurveTo(prev.x / 1000, prev.y / 1000, pointR.x / 1000, pointR.y / 1000, point.x / 1000, point.y / 1000);

                        } else {
                            shape.lineTo(point.x / 1000, point.y / 1000);
                        }
                        prev = point
                        first = false
                    });

                    const extrudeSettings = {
                        steps: 1,
                        depth: height,
                        bevelEnabled: false,
                    };
                    const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

                    if (tokenROLE === 'editor' && plan.cycleActive > -1) {
                        geometry.computeBoundingBox()
                        const cycles = plan.cycles.filter((el, index) => index === plan.cycleActive)
                        const pointX = (geometry.boundingBox.min.x + geometry.boundingBox.max.x) / 2
                        const pointY = (geometry.boundingBox.min.y + geometry.boundingBox.max.y) / 2

                        if (polyPoint(cycles[0]._points, pointX, pointY)) {
                            let materialFace
                            if (polygon.material) {
                                materialFace = new THREE.MeshPhongMaterial();

                                if (polygon.rgbColor) {
                                    materialFace.color = new THREE.Color('rgb(' + polygon.rgb.r + ',' + polygon.rgb.g + ',' + polygon.rgb.b + ')');
                                } else {
                                    materialFace.copy(polygon.material);
                                    materialFace.map = polygon.material.map.clone();
                                    materialFace.map.repeat.set(0.25, 0.25);
                                    materialFace.map.rotation = polygon?.rotation * Math.PI / 180;
                                    materialFace.map.needsUpdate = true;
                                }
                            }
                            const materialSide = new THREE.MeshPhongMaterial({ color: 0xffffff });
                            const floorobj = new THREE.Mesh(geometry, [materialFace, materialSide]);
                            floorobj.rotateX(Math.PI / 2);
                            floorobj.translateZ(-height - heightFromFloor);
                            floorobj.receiveShadow = true;
                            floorobj.name = "floor";
                            floorobj.planObj = polygon;
                            result.add(floorobj);
                        }
                    } else {
                        let materialFace
                        if (polygon.material) {
                            materialFace = new THREE.MeshPhongMaterial();

                            if (polygon.rgbColor) {
                                materialFace.color = new THREE.Color('rgb(' + polygon.rgb.r + ',' + polygon.rgb.g + ',' + polygon.rgb.b + ')');
                            } else {
                                materialFace.copy(polygon.material);
                                materialFace.map = polygon.material.map.clone();
                                materialFace.map.repeat.set(0.25, 0.25);
                                materialFace.map.rotation = polygon?.rotation * Math.PI / 180;
                                materialFace.map.needsUpdate = true;
                            }
                        }
                        const materialSide = new THREE.MeshPhongMaterial({ color: 0xffffff });
                        const floorobj = new THREE.Mesh(geometry, [materialFace, materialSide]);
                        floorobj.rotateX(Math.PI / 2);
                        floorobj.translateZ(-height - heightFromFloor);
                        floorobj.receiveShadow = true;
                        floorobj.name = "floor";
                        floorobj.planObj = polygon;
                        result.add(floorobj);

                        if (centerModule === polygon) {
                            const bb = new THREE.Box3();
                            bb.setFromObject(floorobj);
                            bb.center(orbitControls.target);
                            camera.updateProjectionMatrix();
                            orbitControls.update();
                        }
                    }

                } else {

                    const perimetrV = [];
                    polygon.points.map((point, index) => {
                        const nextIndex = index + 1 > polygon.points.length - 1 ? 0 : index + 1;
                        const currentWall = __walls.find((wall) => (
                            isEqualPoints(wall.nodes[4], point) && isEqualPoints(wall.nodes[5], polygon.points[nextIndex])
                            || isEqualPoints(wall.nodes[5], point) && isEqualPoints(wall.nodes[4], polygon.points[nextIndex]))
                        );

                        if (currentWall?.isBezier) {
                            const inlineBezierLUT = [...currentWall.inlineBezierLUT];

                            if (isEqualPoints(currentWall.nodes[5], point)) inlineBezierLUT.reverse();

                            inlineBezierLUT.forEach((points) => {
                                if (isEqualPoints(currentWall.nodes[5], point)) points.reverse();

                                points.forEach((point) => {
                                    perimetrV.push(new THREE.Vector2(point.x / 1000, point.y / 1000));
                                })
                            })
                        }

                        perimetrV.push(new THREE.Vector2(point.x / 1000, point.y / 1000));
                    });

                    const perimetrT = THREE.ShapeUtils.triangulateShape(perimetrV, []);
                    const floor = new THREE.Geometry();
                    perimetrV.map(point => {
                        floor.vertices.push(new THREE.Vector3(point.x, 0.0, point.y));
                    });

                    perimetrT.map((t, k) => {
                        const face = new THREE.Face3(t[0], t[1], t[2], new THREE.Vector3(0.0, 1.0, 0.0));
                        floor.faces.push(face);
                        const uvs = [
                            new THREE.Vector2(floor.vertices[t[0]].x / 4.000000001, floor.vertices[t[0]].z / 4.000000001),
                            new THREE.Vector2(floor.vertices[t[1]].x / 4.000000001, floor.vertices[t[1]].z / 4.000000001),
                            new THREE.Vector2(floor.vertices[t[2]].x / 4.000000001, floor.vertices[t[2]].z / 4.000000001)
                        ];
                        floor.faceVertexUvs[0].push(uvs);
                    });

                    // const material = mat?mat:new THREE.MeshPhongMaterial();
                    let material
                    material = (polygon.material) ? polygon.material.clone() : new THREE.MeshPhongMaterial();

                    if (polygon.rgbColor) {
                        material.color = new THREE.Color('rgb(' + polygon.rgb.r + ',' + polygon.rgb.g + ',' + polygon.rgb.b + ')');
                        material.map = null;
                    } else {
                        material.map = polygon.material.map.clone();
                        material.map.rotation = polygon?.rotation * Math.PI / 180;
                        material.map.needsUpdate = true;
                    }

                    const floorobj = new THREE.Mesh(new THREE.BufferGeometry().fromGeometry(floor), material);
                    floorobj.receiveShadow = true;
                    floorobj.name = "floor";
                    floorobj.planObj = polygon;
                    result.add(floorobj);

                    if (tokenROLE === 'editor' && plan.cycleActive > -1) {
                        const bb = new THREE.Box3();
                        bb.setFromObject(floorobj);
                        bb.center(orbitControls.target);
                        camera.updateProjectionMatrix();
                        orbitControls.update();
                    }

                    if (centerModule === polygon) {
                        const bb = new THREE.Box3();
                        bb.setFromObject(floorobj);
                        bb.center(orbitControls.target);
                        camera.updateProjectionMatrix();
                        orbitControls.update();
                    }

                }
            });

            // console.log('generateFloor result',result);
            return result;
        };

        const generateRoof = (__cycles, __walls) => {
            // console.log('generateFloor',__cycles);
            const result = new THREE.Group();
            result.userData.type = "roof";
            result.name = 'Roof';
            const roofHeight = 2.7
            __cycles.map(polygon => {

                if (!polygon.showModule || polygon.isFigure) return;

                const roof = [];
                polygon.points.map((point, index) => {
                    const nextIndex = index + 1 > polygon.points.length - 1 ? 0 : index + 1;
                    const currentWall = __walls.find((wall) => (
                        isEqualPoints(wall.nodes[4], point) && isEqualPoints(wall.nodes[5], polygon.points[nextIndex])
                        || isEqualPoints(wall.nodes[5], point) && isEqualPoints(wall.nodes[4], polygon.points[nextIndex]))
                    );

                    if (currentWall?.isBezier) {
                        const inlineBezierLUT = [...currentWall.inlineBezierLUT];

                        if (isEqualPoints(currentWall.nodes[5], point)) inlineBezierLUT.reverse();

                        inlineBezierLUT.forEach((points) => {
                            if (isEqualPoints(currentWall.nodes[5], point)) points.reverse();

                            points.forEach((point) => {
                                roof.push(new THREE.Vector2(point.x / 1000, point.y / 1000));
                            })
                        })
                    }

                    roof.push(new THREE.Vector2(point.x / 1000, point.y / 1000));
                });

                // const height = (!levelFloor)?0.0001:polygon.height/1000;
                const height = 0.0001;

                const shape = new THREE.Shape();
                let first = true;
                let prev = roof[roof.length - 1];
                roof.forEach(point => {

                    let isArc = false;
                    let arcInverse;
                    // __walls.map((wall, index) => {
                    //     if (wall.isArc) {
                    //         const node_x = Math.round(point.x)
                    //         const node_y = Math.round(point.y)
                    //         const prev_x = Math.round(prev.x)
                    //         const prev_y = Math.round(prev.y)
                    //         const a_x = Math.round(wall.a.x)
                    //         const a_y = Math.round(wall.a.y)
                    //         const b_x = Math.round(wall.b.x)
                    //         const b_y = Math.round(wall.b.y)
                    //         if (
                    //             ((node_x===a_x && node_y===a_y) || (node_x===b_x && node_y===b_y)) &&
                    //             ((prev_x===a_x && prev_y===a_y) || (prev_x===b_x && prev_y===b_y))
                    //         ) {
                    //             isArc = true
                    //             arcInverse = wall.arcInverse
                    //         }
                    //     }
                    // });


                    if (first) shape.moveTo(prev.x, prev.y);

                    if (isArc) {

                        const wallA = prev.clone().add(point.clone().sub(prev).multiplyScalar(.5));
                        const wallB = prev

                        let length1 = (Math.ceil((new Vector2(wallA.x, wallA.y))
                            .distanceTo(new Vector2(wallB.x, wallB.y)))).toString()

                        const v = wallA.clone().sub(wallB)
                        const __v = v.clone().rotateAround(new Vector2(0, 0), 90 * (Math.PI / 180)).setLength(length1)
                        const grA = { x: __v.x + wallA.x, y: __v.y + wallA.y }

                        const __v2 = v.clone().rotateAround(new Vector2(0, 0), -90 * (Math.PI / 180)).setLength(length1)
                        const grB = { x: __v2.x + wallA.x, y: __v2.y + wallA.y }

                        const pointR = (!arcInverse) ? grB : grA
                        shape.bezierCurveTo(prev.x / 1000, prev.y / 1000, pointR.x / 1000, pointR.y / 1000, point.x / 1000, point.y / 1000);

                    } else {
                        shape.lineTo(point.x, point.y);
                    }
                    prev = point
                    first = false
                });

                const extrudeSettings = {
                    steps: 1,
                    depth: height,
                    bevelEnabled: false,
                };
                const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
                // const materialFace = new THREE.MeshPhongMaterial();
                // if (polygon.material) {
                //     materialFace.copy(polygon.material);
                //     materialFace.map = polygon.material.map.clone();
                //     materialFace.map.repeat.set(0.25, 0.25);
                //     materialFace.map.needsUpdate = true;
                // }

                const materialFace = new THREE.MeshPhongMaterial({
                    color: 0xFFFFFF,
                    side: THREE.DoubleSide
                });
                const materialSide = new THREE.MeshPhongMaterial({ color: 0xffffff, side: THREE.DoubleSide });
                const floorobj = new THREE.Mesh(geometry, [materialFace, materialSide]);
                floorobj.rotateX(Math.PI / 2);
                floorobj.translateZ(-roofHeight);
                floorobj.receiveShadow = true;
                floorobj.name = "roof";
                floorobj.planObj = polygon;
                result.add(floorobj);

                if (centerModule === polygon) {
                    const bb = new THREE.Box3();
                    bb.setFromObject(floorobj);
                    bb.center(orbitControls.target);
                    camera.updateProjectionMatrix();
                    orbitControls.update();
                }
            });

            return result;
        };

        const setDoors = () => {

            let cycles, walls
            if (tokenROLE === 'editor' && plan.cycleActive > -1) {
                cycles = plan.cycles.filter((el, index) => index === plan.cycleActive)
                walls = plan.bWalls.filter(wall => {
                    const _points = cycles[0]._points

                    let pointA = _points.filter(point => Math.round(point.x) === wall.a.x && Math.round(point.y) === wall.a.y)
                    let pointB = _points.filter(point => Math.round(point.x) === wall.b.x && Math.round(point.y) === wall.b.y)

                    return pointA.length > 0 && pointB.length > 0
                })
            } else {
                walls = plan.bWalls
            }

            const result = new THREE.Group();
            result.name = 'Doors';
            walls.map(wall => {

                if (!wall.showModule) return

                const group = new THREE.Group();

                //ВСТАВЛЯЕМ ДВЕРИ
                if (doorModel !== null && !wall.isArc) {
                    wall.objects.map(o => {
                        if (o.isDoor) {
                            const wnd = doorModel.clone();
                            const wndMesh = getMesh(wnd);
                            wndMesh.geometry = wndMesh.geometry.clone();
                            resizeDoor(wnd, o.width / 1000, o.height / 1000);

                            const wallVector = wall.isBezier ? o.objB.clone().sub(o.objA) : wall.mainLink.b.clone().sub(wall.mainLink.a);
                            const angle = -wallVector.angle();
                            const l = wall.isBezier ? 0 : o.pos;

                            const __v = wallVector.clone().setLength(o.width / 2 + l)
                            // const __x = wall.mainLink.a.x+__v.x
                            // const __z = wall.mainLink.a.y+__v.y
                            const __x = o.DepthPos3d.x + __v.x
                            const __z = o.DepthPos3d.y + __v.y

                            const depthScale = (o.depth / .15) / 1000
                            wnd.scale.set(1, 1, depthScale)

                            wnd.translateX(__x / 1000);
                            wnd.translateY((o.heightFromFloor * 1 + o.height / 2) / 1000);
                            wnd.translateZ(__z / 1000);
                            wnd.rotateY(angle)
                            wnd.name = 'wallObj'
                            group.add(wnd);
                        }
                    });
                }
                result.add(group);
            });
            return result;
        }

        const setWindows = () => {

            let cycles, walls
            if (tokenROLE === 'editor' && plan.cycleActive > -1) {
                cycles = plan.cycles.filter((el, index) => index === plan.cycleActive)
                walls = plan.bWalls.filter(wall => {
                    const _points = cycles[0]._points

                    let pointA = _points.filter(point => Math.round(point.x) === wall.a.x && Math.round(point.y) === wall.a.y)
                    let pointB = _points.filter(point => Math.round(point.x) === wall.b.x && Math.round(point.y) === wall.b.y)

                    return pointA.length > 0 && pointB.length > 0
                })
            } else {
                walls = plan.bWalls;
            }

            const result = new THREE.Group();
            result.name = 'Windows';
            walls.map(wall => {

                if (!wall.showModule) return

                const group = new THREE.Group();

                //ВСТАВЛЯЕМ ОКНА
                if (windowModel !== null && !wall.isArc) {
                    wall.objects.map(o => {
                        if (o.isWindow) {
                            const wnd = windowModel.clone();
                            const wndMesh = getMesh(wnd);

                            const outerWidth = new THREE.Vector2(o.rA1Inner.x, o.rA1Inner.y).sub(new THREE.Vector2(o.rB1Inner.x, o.rB1Inner.y)).length();
                            const innerWidth = new THREE.Vector2(o.rA2Inner.x, o.rA2Inner.y).sub(new THREE.Vector2(o.rB2Inner.x, o.rB2Inner.y)).length();

                            const width = o?.isCorner ? ((outerWidth + innerWidth) / 2) : o.width;
                            const widthDif = (o?.isCorner && o.len1 === 0) ? (o?.width - width) : 0

                            wndMesh.geometry = wndMesh.geometry.clone();
                            resizeWindow(wnd, width / 1000, o.height / 1000);

                            let wallVector = wall.isBezier ? o.objB.clone().sub(o.objA) : wall.mainLink.b.clone().sub(wall.mainLink.a);
                            const angle = -wallVector.angle();
                            const l = wall.isBezier ? 0 : (o.pos + widthDif);

                            const __v = wallVector.clone().setLength(width / 2 + l)
                            // const __x = wall.a.x+__v.x
                            // const __z = wall.a.y+__v.y
                            const __x = o.DepthPos3d.x + __v.x
                            const __z = o.DepthPos3d.y + __v.y

                            const depthScale = (o.depth / .15) / 1000
                            wnd.scale.set(1, 1, depthScale)

                            wnd.translateX(__x / 1000);
                            wnd.translateY((o.heightFromFloor * 1 + o.height / 2) / 1000);
                            wnd.translateZ(__z / 1000);
                            wnd.rotateY(angle)
                            wnd.name = 'wallObj'
                            group.add(wnd);
                        }
                    });
                }
                result.add(group);
            });
            return result;
        }

        const setElectricSocket = () => {
            const result = new THREE.Group();
            result.name = 'objectOnWall';

            const fillSockets = (parent) => {
                if (!parent?.showModule) return

                const group = new THREE.Group();

                //ВСТАВЛЯЕМ Розетку
                if (electricSocketModel !== null && !parent?.isArc) {
                    parent.objects.forEach(o => {
                        const copySocket = { ...o };
                        copySocket.DepthPos3d = { ...o.DepthPos3d };
                        const socketVector = (parent?.isBezier || parent?.isColumn) ?
                            o.objB.clone().sub(o.objA) :
                            parent.mainLink.b.clone().sub(parent.mainLink.a);
                        if (copySocket.isElectricSocket) {
                            for (let i = 1; i <= copySocket.count; i++) {
                                group.add(transformObjectOnWall(electricSocketModel, copySocket, parent));
                                if (copySocket.type === 'horizontally') {
                                    copySocket.DepthPos3d.x += copySocket.width * Math.cos(-socketVector.angle());
                                    copySocket.DepthPos3d.y += copySocket.width * Math.sin(socketVector.angle());
                                } else {
                                    copySocket.heightFromFloor += copySocket.height;
                                }
                            }
                        }
                    });
                }
                result.add(group);
            }

            plan.bWalls.forEach(fillSockets);
            plan.columns.forEach(fillSockets);

            return result;
        }

        const setSwitch = () => {
            const result = new THREE.Group();
            result.name = 'objectOnWall';

            const fillSwitches = (parent) => {
                if (!parent.showModule) return
                const group = new THREE.Group();
                //ВСТАВЛЯЕМ Выключатель
                if (switchModel !== null && !parent?.isArc) {
                    parent.objects.forEach(o => {
                        const copySwitch = { ...o };
                        copySwitch.DepthPos3d = { ...o.DepthPos3d };
                        const socketVector = (parent?.isBezier || parent?.isColumn) ?
                            o.objB.clone().sub(o.objA) :
                            parent.mainLink.b.clone().sub(parent.mainLink.a);
                        if (copySwitch.isSwitch) {
                            for (let i = 1; i <= copySwitch.count; i++) {
                                group.add(transformObjectOnWall(switchModel, copySwitch, parent));
                                if (copySwitch.type === 'horizontally') {
                                    copySwitch.DepthPos3d.x += copySwitch.width * Math.cos(-socketVector.angle());
                                    copySwitch.DepthPos3d.y += copySwitch.width * Math.sin(socketVector.angle());
                                } else {
                                    copySwitch.heightFromFloor += copySwitch.height;
                                }
                            }
                        }
                    });
                }
                result.add(group);
            }

            plan.bWalls.forEach(fillSwitches);
            plan.columns.forEach(fillSwitches);

            return result;
        }

        const setOutletElectricalWire = () => {
            const result = new THREE.Group();
            result.name = 'objectOnWall';

            const fillWires = (parent) => {
                if (!parent.showModule) return
                const group = new THREE.Group();
                //ВСТАВЛЯЕМ Выключатель
                if (switchModel !== null && !parent?.isArc) {
                    parent.objects.forEach(o => {
                        const copyWire = { ...o };
                        copyWire.DepthPos3d = { ...o.DepthPos3d };
                        const wireVector = (parent?.isBezier || parent?.isColumn) ?
                            o.objB.clone().sub(o.objA) :
                            parent.mainLink.b.clone().sub(parent.mainLink.a);
                        if (copyWire.outletElectricalWire) {
                            for (let i = 1; i <= copyWire.count; i++) {
                                group.add(transformObjectOnWall(switchModel, copyWire, parent));
                                if (copyWire.type === 'horizontally') {
                                    copyWire.DepthPos3d.x += copyWire.width * Math.cos(-wireVector.angle());
                                    copyWire.DepthPos3d.y += copyWire.width * Math.sin(wireVector.angle());
                                } else {
                                    copyWire.heightFromFloor += copyWire.height;
                                }
                            }
                        }
                    });
                }
                result.add(group);
            }

            plan.bWalls.forEach(fillWires);
            plan.columns.forEach(fillWires);

            return result;
        }

        const setHeatingBattery = () => {
            const result = new THREE.Group();
            result.name = 'objectOnWall';

            const fillBatteries = (parent) => {
                if (!parent.showModule) return
                const group = new THREE.Group();
                //ВСТАВЛЯЕМ Выключатель
                if (heatingBatteryModel !== null && !parent?.isArc) {
                    parent.objects.forEach(o => {
                        if (o.isHeatingBattery) {
                            group.add(transformObjectOnWall(heatingBatteryModel, o, parent));
                        }
                    });
                }
                result.add(group);
            }

            plan.bWalls.forEach(fillBatteries);
            plan.columns.forEach(fillBatteries);

            return result;
        }

        const setElectricPanel = () => {
            const result = new THREE.Group();
            result.name = 'objectOnWall';

            const fillElectricPanels = (parent) => {
                if (!parent?.showModule) return
                const group = new THREE.Group();
                //ВСТАВЛЯЕМ Электрощит
                if (electricPanelModel !== null && !parent?.isArc) {
                    parent.objects.forEach(o => {
                        if (o.isElectricPanel) {
                            group.add(transformObjectOnWall(electricPanelModel, o, parent));
                        }
                    });
                }
                result.add(group);
            }

            plan.bWalls.forEach(fillElectricPanels);
            plan.columns.forEach(fillElectricPanels);

            return result;
        }

        const setRedCube = () => {
            const result = new THREE.Group();
            result.name = 'objectOnWall';

            const fillFigures = (parent) => {
                if (!parent.showModule) return
                const group = new THREE.Group();
                !parent?.isArc && parent.objects.forEach(o => {
                    if (o.isRedCube) {
                        const geometry = new THREE.BoxGeometry(1, 1, 1);
                        geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0.5, 0.5));
                        const material = new THREE.MeshBasicMaterial({ color: new THREE.Color(`rgba(${o.rgb.r},${o.rgb.g}, ${o.rgb.b})`) });
                        const redCube = new THREE.Mesh(geometry, material);
                        group.add(transformObjectOnWall(redCube, o, parent));
                    }
                    if (o.isCylinder) {
                        const geometry = new THREE.CylinderGeometry(1, 1, 1, 20, 20);
                        geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0.5, 1));
                        const material = new THREE.MeshBasicMaterial({ color: new THREE.Color(`rgba(${o.rgb.r},${o.rgb.g}, ${o.rgb.b})`) });
                        const redCube = new THREE.Mesh(geometry, material);
                        group.add(transformObjectOnWall(redCube, o, parent));
                    }
                });
                result.add(group);
            }

            plan.bWalls.forEach(fillFigures);
            plan.columns.forEach(fillFigures);

            return result;
        }

        const prepareWalls = (walls) => {

            // Сброс парных стен
            plan.bWalls.map(wall => {
                wall.pairsWalls = [];
            });

            const linksWall = plan.links.filter(l => l.isWall);
            linksWall.forEach((linkCur, indexCur) => {
                linksWall.forEach((link, index) => {
                    if (index > indexCur) {
                        if (linkCur.depth >= link.depth && Math.abs(linkCur.depth - link.depth) < 50) {
                            // Если толщина стен одинаковая или разнится не более, чем на 50мм

                            const crossPoint1 = getIntersectionLongLines(linkCur, link);
                            if (!crossPoint1) {
                                // Стены параллельны
                                const v = linkCur.a.clone().sub(linkCur.b);
                                const rotate = (linkCur.lrBuild === 'left') ? 90 : -90;
                                const __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(10000);
                                const parallelA = new Node(linkCur.a.x, linkCur.a.y);
                                const parallelB = new Node(__v.x + linkCur.a.x, __v.y + linkCur.a.y);
                                const linkCurPerpendicular = new Link(parallelA, parallelB);
                                const crossPoint2 = getIntersectionLongLines(linkCurPerpendicular, link);
                                if (crossPoint2) {
                                    const linksPerpendicular = new Link(parallelA, crossPoint2);

                                    const dist = linksPerpendicular.length;

                                    if (dist <= linkCur.depth + 10 && dist > linkCur.depth - 10) {
                                        // console.log('Стены параллельны => dist',dist)
                                        // linkPairs.push(linkCur);
                                        // linkPairs.push(link);

                                        let wall1index = -1;
                                        const wall1 = plan.bWalls.filter((w, index) => {
                                            if (w.mainLink === linkCur) {
                                                wall1index = index;
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        })[0];
                                        // let v = linkCur.a.clone().sub(linkCur.b);
                                        // let rotate = (linkCur.lrBuild==='left')?90:-90;
                                        // let __v = v.clone().rotateAround(new Vector2(0,0), rotate*(Math.PI/180)).setLength(linkCur.depth-5);
                                        // let parallelA = new Node(__v.x+linkCur.a.x,__v.y+linkCur.a.y);
                                        // let parallelB = new Node(__v.x+linkCur.b.x,__v.y+linkCur.b.y);
                                        // wall1.nodes[4] = parallelA;
                                        // wall1.nodes[5] = parallelB;


                                        let wall2index = -1;
                                        const wall2 = plan.bWalls.filter((w, index) => {
                                            if (w.mainLink === link) {
                                                wall2index = index;
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        })[0];
                                        // v = link.a.clone().sub(link.b);
                                        // rotate = (link.lrBuild==='left')?90:-90;
                                        // __v = v.clone().rotateAround(new Vector2(0,0), rotate*(Math.PI/180)).setLength(link.depth-5);
                                        // parallelA = new Node(__v.x+link.a.x,__v.y+link.a.y);
                                        // parallelB = new Node(__v.x+link.b.x,__v.y+link.b.y);
                                        // wall2.nodes[4] = parallelA;
                                        // wall2.nodes[5] = parallelB;

                                        wall1.pairsWalls.push(wall2index);
                                        wall2.pairsWalls.push(wall1index);
                                    }
                                }
                            }
                        }
                    }
                });
            });

            // console.log('plan.bWalls',plan.bWalls)
            plan.bWalls.forEach(wall => {
                // Обычная односторонняя стена без проёмов
                const mainLink = wall.mainLink;
                const wallA1 = wall.innerLink.a;
                const wallA2 = wall.innerLink.b;
                const wallB1 = wall.parallelLink.a;
                const wallB2 = wall.parallelLink.b;
                const controlA = wall.mainLink.controlA;
                const controlB = wall.mainLink.controlB;
                const outlineBezier = wall.outlineBezier;
                const inlineBezier = wall.inlineBezier;
                const inlineBezierLUT = wall.inlineBezierLUT;

                const isCorner = wall?.objects?.some((obj) => obj?.isCorner);

                if (wall.isBezier) {
                    wall.polygons3d = {
                        parallelSide: [
                            { x: wallB1.x / 1000, y: wallB1.y / 1000 },
                            { x: wallB2.x / 1000, y: wallB2.y / 1000 },
                        ],
                        mainBezier: [
                            { x: wallA1.x / 1000, y: wallA1.y / 1000 },
                            { x: controlA.x / 1000, y: controlA.y / 1000 },
                            { x: controlB.x / 1000, y: controlB.y / 1000 },
                            { x: wallA2.x / 1000, y: wallA2.y / 1000 },
                        ],
                        inlinePoints:
                            [...inlineBezierLUT.map((points) => {
                                return points.map((point) => {
                                    return { x: point.x / 1000, y: point.y / 1000 }
                                })
                            })].flat(),
                        inlineBezier:
                            [...inlineBezier.map((bezier) => {
                                return bezier.points.map((point) => {
                                    return { x: point.x / 1000, y: point.y / 1000 }
                                })
                            })].reverse(),
                        outlineBezier:
                            [...outlineBezier.map((bezier) => {
                                return bezier.points.map((point) => {
                                    return { x: point.x / 1000, y: point.y / 1000 }
                                })
                            })],
                        holes: [
                            ...wall.objects.filter((item) => item.isDoor || item.isHole || item.isWindow)
                        ]
                    }
                } else {
                    wall.polygons3d = {
                        front: [ // Лицевая сторона
                            [
                                [wallA1.x / 1000, 0.0, wallA1.y / 1000],
                                [wallA1.x / 1000, mainLink.height / 1000, wallA1.y / 1000],
                                [wallA2.x / 1000, 0.0, wallA2.y / 1000],
                                [wallA2.x / 1000, mainLink.height / 1000, wallA2.y / 1000]
                            ]
                        ],
                        back: [ // Сторона, обратная лицевой
                            [
                                [wallB1.x / 1000, 0.0, wallB1.y / 1000],
                                [wallB1.x / 1000, mainLink.height / 1000, wallB1.y / 1000],
                                [wallB2.x / 1000, 0.0, wallB2.y / 1000],
                                [wallB2.x / 1000, mainLink.height / 1000, wallB2.y / 1000]
                            ]
                        ],
                        sides: [
                            [ // Верх
                                [wallA1.x / 1000, mainLink.height / 1000, wallA1.y / 1000],
                                [wallA2.x / 1000, mainLink.height / 1000, wallA2.y / 1000],
                                [wallB1.x / 1000, mainLink.height / 1000, wallB1.y / 1000],
                                [wallB2.x / 1000, mainLink.height / 1000, wallB2.y / 1000]
                            ],
                            [ // Низ
                                [wallA1.x / 1000, 0.0, wallA1.y / 1000],
                                [wallA2.x / 1000, 0.0, wallA2.y / 1000],
                                [wallB1.x / 1000, 0.0, wallB1.y / 1000],
                                [wallB2.x / 1000, 0.0, wallB2.y / 1000]
                            ],
                            ...(!isCorner ? [[ // Слева
                                [wallA1.x / 1000, 0.0, wallA1.y / 1000],
                                [wallA1.x / 1000, mainLink.height / 1000, wallA1.y / 1000],
                                [wallB1.x / 1000, 0.0, wallB1.y / 1000],
                                [wallB1.x / 1000, mainLink.height / 1000, wallB1.y / 1000]
                            ]] : []),
                            ...(!isCorner ? [[ // Справа
                                [wallA2.x / 1000, 0.0, wallA2.y / 1000],
                                [wallA2.x / 1000, mainLink.height / 1000, wallA2.y / 1000],
                                [wallB2.x / 1000, 0.0, wallB2.y / 1000],
                                [wallB2.x / 1000, mainLink.height / 1000, wallB2.y / 1000]
                            ]] : []),
                        ],
                        holes: [
                            ...wall.objects.filter((item) => item.isDoor || item.isHole || item.isWindow)
                        ]
                    };
                }

                const wallObjectsSlices = [];
                wall.isExtrudeWall = wall.objects.some((object) => object?.isHole && object?.isCircleHole);

                if (!wall.isArc && !wall.isBezier && wall.objects && !wall.isExtrudeWall) {
                    wall.objects.filter((item) => item.isDoor || item.isHole || item.isWindow).forEach(obj => {
                        wallObjectsSlices.push({
                            rA1: obj.rA13d,
                            rA2: obj.rA23d,
                            rB1: obj.rB13d,
                            rB2: obj.rB23d,

                            rA1Inner: obj.rA1Inner,
                            rB1Inner: obj.rB1Inner,
                            rA2Inner: obj.rA2Inner,
                            rB2Inner: obj.rB2Inner,

                            pos: obj.pos,
                            width: obj.width,
                            height: obj.height,
                            heightFromFloor: obj.heightFromFloor,
                            isHole: obj.isHole,
                            depth: obj.depth,

                            isCorner: obj?.isCorner,
                            len1: obj?.len1,
                            len2: obj?.len2,
                        });
                    });
                }
                wallObjectsSlices.sort((a, b) => {
                    return a.pos - b.pos
                });


                // Нарезки лицевой стороны по объектам на той же стене
                const wallSlicesByObjects = [];
                wallSlicesByObjects.push({ x: wallA1.x, y: wallA1.y });
                wallSlicesByObjects.push({ x: wallA2.x, y: wallA2.y });
                wallObjectsSlices.forEach(objectSlice => {
                    if (objectSlice && objectSlice?.rA1 && objectSlice?.rB1) {
                        wallSlicesByObjects.push({ x: objectSlice.rA1.x, y: objectSlice.rA1.y });
                        wallSlicesByObjects.push({ x: objectSlice.rB1.x, y: objectSlice.rB1.y });
                    }
                });

                // Нарезка задней части стены под закрытие в местах без пересечений с параллельными стенами
                // + дополнительная нарезка лицевой части стены под объекты с парных стен
                {
                    const wallSlicesByPairsWalls = [];
                    wallSlicesByPairsWalls.push({ x: wallB1.x, y: wallB1.y });
                    wallSlicesByPairsWalls.push({ x: wallB2.x, y: wallB2.y });

                    if (wall.pairsWalls.length > 0) {
                        wall.polygons3d.back = [];

                        // let v = wallB1.clone().sub(wallB2);
                        let v = wallA1.clone().sub(wallA2);
                        let rotate = (mainLink.lrBuild === 'left') ? 90 : -90;
                        let __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(10000);

                        // const wallB1_1 = wallB1.clone();
                        // const wallB1_2 = wallB1.clone();
                        const wallB1_1 = wallA1.clone();
                        const wallB1_2 = wallA1.clone();
                        wallB1_1.x = wallB1_1.x + __v.x;
                        wallB1_1.y = wallB1_1.y + __v.y;
                        wallB1_2.x = wallB1_2.x - __v.x;
                        wallB1_2.y = wallB1_2.y - __v.y;

                        // const wallB2_1 = wallB2.clone();
                        // const wallB2_2 = wallB2.clone();
                        const wallB2_1 = wallA2.clone();
                        const wallB2_2 = wallA2.clone();
                        wallB2_1.x = wallB2_1.x + __v.x;
                        wallB2_1.y = wallB2_1.y + __v.y;
                        wallB2_2.x = wallB2_2.x - __v.x;
                        wallB2_2.y = wallB2_2.y - __v.y;

                        wall.pairsWalls.forEach((pairWallIndex, index) => {

                            const pairWall = plan.bWalls[pairWallIndex];

                            const pairWallA1 = pairWall.mainLink.a;
                            const pairWallA2 = pairWall.mainLink.b;

                            const crossPointMainA = getIntersection(
                                { x: wallB1_1, y: wallB1_2 },
                                { x: pairWallA1, y: pairWallA2 }
                            );
                            const crossPointMainB = getIntersection(
                                { x: wallB2_1, y: wallB2_2 },
                                { x: pairWallA1, y: pairWallA2 }
                            );

                            if (!crossPointMainA || !crossPointMainB) {

                                let v = pairWallA1.clone().sub(pairWallA2);
                                let rotate = (pairWall.mainLink.lrBuild === 'left') ? 90 : -90;
                                let __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(10000);

                                const pairWallA1_1 = pairWallA1.clone();
                                const pairWallA1_2 = pairWallA1.clone();
                                pairWallA1_1.x = pairWallA1_1.x + __v.x;
                                pairWallA1_1.y = pairWallA1_1.y + __v.y;
                                pairWallA1_2.x = pairWallA1_2.x - __v.x;
                                pairWallA1_2.y = pairWallA1_2.y - __v.y;

                                const pairWallA2_1 = pairWallA2.clone();
                                const pairWallA2_2 = pairWallA2.clone();
                                pairWallA2_1.x = pairWallA2_1.x + __v.x;
                                pairWallA2_1.y = pairWallA2_1.y + __v.y;
                                pairWallA2_2.x = pairWallA2_2.x - __v.x;
                                pairWallA2_2.y = pairWallA2_2.y - __v.y;

                                const crossPointPairA = getIntersection(
                                    { x: pairWallA1_1, y: pairWallA1_2 },
                                    { x: wallB1, y: wallB2 }
                                );
                                const crossPointPairB = getIntersection(
                                    { x: pairWallA2_1, y: pairWallA2_2 },
                                    { x: wallB1, y: wallB2 }
                                );

                                if (crossPointPairA) {
                                    wallSlicesByPairsWalls.push(crossPointPairA);
                                }
                                if (crossPointPairB) {
                                    wallSlicesByPairsWalls.push(crossPointPairB);
                                }

                            } else {
                                // Есть оба пересечения - задника нет вообще
                            }

                            // дополнительная нарезка лицевой части стены под объекты с парных стен
                            pairWall.objects.map(obj => {
                                if (obj.isHole && obj.depth < (pairWall.mainLink.depth + pairWall.mainLink.innerDepth)) {
                                    // Ниша
                                } else {
                                    const objectSliceA = new Node(obj.rA13d.x, obj.rA13d.y);
                                    const objectSliceB = new Node(obj.rB13d.x, obj.rB13d.y);

                                    let v = objectSliceA.clone().sub(objectSliceB);
                                    let rotate = (mainLink.lrBuild === 'left') ? 90 : -90;
                                    let __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(10000000);

                                    const objectSliceAPerp1 = objectSliceA.clone();
                                    const objectSliceAPerp2 = objectSliceA.clone();
                                    objectSliceAPerp1.x = objectSliceAPerp1.x + __v.x;
                                    objectSliceAPerp1.y = objectSliceAPerp1.y + __v.y;
                                    objectSliceAPerp2.x = objectSliceAPerp2.x - __v.x;
                                    objectSliceAPerp2.y = objectSliceAPerp2.y - __v.y;

                                    const objectSliceBPerp1 = objectSliceB.clone();
                                    const objectSliceBPerp2 = objectSliceB.clone();
                                    objectSliceBPerp1.x = objectSliceBPerp1.x + __v.x;
                                    objectSliceBPerp1.y = objectSliceBPerp1.y + __v.y;
                                    objectSliceBPerp2.x = objectSliceBPerp2.x - __v.x;
                                    objectSliceBPerp2.y = objectSliceBPerp2.y - __v.y;

                                    const crossPointA = getIntersection(
                                        { x: objectSliceAPerp1, y: objectSliceAPerp2 },
                                        { x: wallA1, y: wallA2 }
                                    );
                                    if (crossPointA) {
                                        wallSlicesByObjects.push({ x: crossPointA.x, y: crossPointA.y });
                                    }

                                    const crossPointB = getIntersection(
                                        { x: objectSliceBPerp1, y: objectSliceBPerp2 },
                                        { x: wallA1, y: wallA2 }
                                    );
                                    if (crossPointB) {
                                        wallSlicesByObjects.push({ x: crossPointB.x, y: crossPointB.y });
                                    }
                                }
                            });
                        });
                    }
                    wallObjectsSlices.forEach(objectSlice => {
                        wallSlicesByPairsWalls.push({ x: objectSlice.rA2.x, y: objectSlice.rA2.y });
                        wallSlicesByPairsWalls.push({ x: objectSlice.rB2.x, y: objectSlice.rB2.y });
                    });
                    wallSlicesByPairsWalls.sort((a, b) => {
                        return Math.round(a.x) - Math.round(b.x) || Math.round(a.y) - Math.round(b.y)
                    });

                    if (wallSlicesByPairsWalls.length > 2) {

                        wall.polygons3d.back = [];

                        wallSlicesByPairsWalls.forEach((point, index) => {
                            if (index > 0) {

                                const sliceNodeA = new Node(wallSlicesByPairsWalls[index - 1].x, wallSlicesByPairsWalls[index - 1].y);
                                const sliceNodeB = new Node(point.x, point.y);
                                const sliceCenterV = sliceNodeA.clone().add(sliceNodeB.clone().sub(sliceNodeA).multiplyScalar(0.5));

                                let v = sliceCenterV.clone().sub(sliceNodeB);
                                let rotate = (mainLink.lrBuild === 'left') ? 90 : -90;
                                let __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(10000000);

                                const sliceCenterPerp1 = sliceCenterV.clone();
                                const sliceCenterPerp2 = sliceCenterV.clone();
                                sliceCenterPerp1.x = sliceCenterPerp1.x + __v.x;
                                sliceCenterPerp1.y = sliceCenterPerp1.y + __v.y;
                                sliceCenterPerp2.x = sliceCenterPerp2.x - __v.x;
                                sliceCenterPerp2.y = sliceCenterPerp2.y - __v.y;

                                let crossPointPW = false;
                                wall.pairsWalls.forEach(pairWallIndex => {
                                    const pairWall = plan.bWalls[pairWallIndex];
                                    if (!crossPointPW) {
                                        const pairWallA1 = pairWall.mainLink.a;
                                        const pairWallA2 = pairWall.mainLink.b;

                                        crossPointPW = getIntersection(
                                            { x: sliceCenterPerp1, y: sliceCenterPerp2 },
                                            { x: pairWallA1, y: pairWallA2 }
                                        );
                                    }
                                });
                                if (!crossPointPW) {

                                    let crossPointWO = false;
                                    wallObjectsSlices.forEach(objectSlice => {
                                        if (!crossPointWO) {
                                            if (objectSlice.isHole && objectSlice.depth < (mainLink.depth + mainLink.innerDepth)) {
                                                // Ниша
                                            } else {
                                                const objectNodeA = new Node(objectSlice.rA2.x, objectSlice.rA2.y);
                                                const objectNodeB = new Node(objectSlice.rB2.x, objectSlice.rB2.y);

                                                crossPointWO = getIntersection(
                                                    { x: sliceCenterPerp1, y: sliceCenterPerp2 },
                                                    { x: objectNodeA, y: objectNodeB }
                                                );
                                            }
                                        }
                                    });

                                    if (crossPointWO) {
                                        // Проём в стене
                                    } else {
                                        wall.polygons3d.back.push(
                                            [
                                                [sliceNodeA.x / 1000, 0.0, sliceNodeA.y / 1000],
                                                [sliceNodeA.x / 1000, mainLink.height / 1000, sliceNodeA.y / 1000],
                                                [sliceNodeB.x / 1000, 0.0, sliceNodeB.y / 1000],
                                                [sliceNodeB.x / 1000, mainLink.height / 1000, sliceNodeB.y / 1000]
                                            ]
                                        );
                                    }
                                }
                            }
                        });

                    }
                }

                // Нарезка лицевой части стены
                {
                    // console.log('wallSlicesByObjects',wallSlicesByObjects)
                    wallSlicesByObjects.sort((a, b) => {
                        return Math.round(a.x) - Math.round(b.x) || Math.round(a.y) - Math.round(b.y)
                    });
                    if (wallSlicesByObjects.length > 2) {

                        wall.polygons3d.front = [];

                        wallSlicesByObjects.forEach((point, index) => {
                            if (index > 0) {

                                const sliceNodeA = new Node(wallSlicesByObjects[index - 1].x, wallSlicesByObjects[index - 1].y);
                                const sliceNodeB = new Node(point.x, point.y);
                                const sliceCenterV = sliceNodeA.clone().add(sliceNodeB.clone().sub(sliceNodeA).multiplyScalar(0.5));

                                let v = sliceCenterV.clone().sub(sliceNodeB);
                                let rotate = (mainLink.lrBuild === 'left') ? 90 : -90;
                                let __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(10000000);

                                const sliceCenterPerp1 = sliceCenterV.clone();
                                const sliceCenterPerp2 = sliceCenterV.clone();
                                sliceCenterPerp1.x = sliceCenterPerp1.x + __v.x;
                                sliceCenterPerp1.y = sliceCenterPerp1.y + __v.y;
                                sliceCenterPerp2.x = sliceCenterPerp2.x - __v.x;
                                sliceCenterPerp2.y = sliceCenterPerp2.y - __v.y;

                                let crossPointWO = false;
                                wallObjectsSlices.forEach(objectSlice => {
                                    if (!crossPointWO && objectSlice?.rA1 && objectSlice?.rB1) {
                                        // if (objectSlice.isHole && objectSlice.depth < mainLink.depth) {
                                        //     // Ниши не обрабатываем для объектов на лицевой стороне текущей стены
                                        // } else {
                                        const objectNodeA = new Node(objectSlice.rA1.x, objectSlice.rA1.y);
                                        const objectNodeB = new Node(objectSlice.rB1.x, objectSlice.rB1.y);

                                        crossPointWO = getIntersection(
                                            { x: sliceCenterPerp1, y: sliceCenterPerp2 },
                                            { x: objectNodeA, y: objectNodeB }
                                        );
                                        // }
                                    }
                                });
                                let crossPointPairsWO = false;
                                wall.pairsWalls.forEach(pairWallIndex => {
                                    const pairWall = plan.bWalls[pairWallIndex];
                                    // console.log('pairWall',pairWall)
                                    if (!crossPointPairsWO) {
                                        pairWall.objects.map(obj => {
                                            if (!crossPointPairsWO) {
                                                if (obj.isHole && obj.depth < (pairWall.mainLink.depth + pairWall.mainLink.innerDepth)) {
                                                    // Ниша
                                                } else {
                                                    const objectNodeA = new Node(obj.rA13d.x, obj.rA13d.y);
                                                    const objectNodeB = new Node(obj.rB13d.x, obj.rB13d.y);

                                                    crossPointPairsWO = getIntersection(
                                                        { x: sliceCenterPerp1, y: sliceCenterPerp2 },
                                                        { x: objectNodeA, y: objectNodeB }
                                                    );
                                                    if (!crossPointPairsWO) {
                                                        const objectNodeA1 = new Node(obj.rA23d.x, obj.rA23d.y);
                                                        const objectNodeB1 = new Node(obj.rB23d.x, obj.rB23d.y);

                                                        crossPointPairsWO = getIntersection(
                                                            { x: sliceCenterPerp1, y: sliceCenterPerp2 },
                                                            { x: objectNodeA1, y: objectNodeB1 }
                                                        );
                                                    }

                                                    if (crossPointPairsWO) {

                                                        let __y, rB1, rB2;
                                                        __y = (obj.heightFromFloor * 1 + obj.height * 1) / 1000;

                                                        if (__y < mainLink.height / 1000) {

                                                            rB1 = obj.rB23d
                                                            rB2 = obj.rA23d
                                                            wall.polygons3d.front.push(
                                                                [
                                                                    [rB1.x / 1000, __y, rB1.y / 1000],
                                                                    [rB2.x / 1000, __y, rB2.y / 1000],
                                                                    [rB1.x / 1000, mainLink.height / 1000, rB1.y / 1000],
                                                                    [rB2.x / 1000, mainLink.height / 1000, rB2.y / 1000],
                                                                ]
                                                            );
                                                        }


                                                        if (obj.heightFromFloor > 0) {

                                                            __y = obj.heightFromFloor / 1000

                                                            rB1 = obj.rB23d
                                                            rB2 = obj.rA23d
                                                            wall.polygons3d.front.push(
                                                                [
                                                                    [rB1.x / 1000, __y, rB1.y / 1000],
                                                                    [rB2.x / 1000, __y, rB2.y / 1000],
                                                                    [rB1.x / 1000, 0.0, rB1.y / 1000],
                                                                    [rB2.x / 1000, 0.0, rB2.y / 1000],
                                                                ]
                                                            );
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                    }
                                });

                                if (crossPointWO || crossPointPairsWO) {
                                    // Проём в стене
                                } else {
                                    wall.polygons3d.front.push(
                                        [
                                            [sliceNodeA.x / 1000, 0.0, sliceNodeA.y / 1000],
                                            [sliceNodeA.x / 1000, mainLink.height / 1000, sliceNodeA.y / 1000],
                                            [sliceNodeB.x / 1000, 0.0, sliceNodeB.y / 1000],
                                            [sliceNodeB.x / 1000, mainLink.height / 1000, sliceNodeB.y / 1000]
                                        ]
                                    );
                                }
                            }
                        });

                        // console.log('wallSlicesByObjects',wallSlicesByObjects)
                    }
                }
                // Откосы, лицевые и задние стороны проемов
                {
                    let __y, rB1, rB2;
                    wallObjectsSlices.filter((obj) => obj?.rA1 && obj?.rB1).forEach(objSlice => {
                        const topZ = (objSlice.heightFromFloor * 1 + objSlice.height * 1);
                        const botZ = (objSlice.heightFromFloor > 0) ? objSlice.heightFromFloor : 0;

                        let a = {
                            x: objSlice.rA1.x,
                            y: objSlice.rA1.y,
                            z: topZ
                        },
                            b = {
                                x: objSlice.rA2.x,
                                y: objSlice.rA2.y,
                                z: topZ
                            },
                            c = {
                                x: objSlice.rB1.x,
                                y: objSlice.rB1.y,
                                z: topZ
                            },
                            d = {
                                x: objSlice.rB2.x,
                                y: objSlice.rB2.y,
                                z: topZ
                            },
                            a1 = {
                                x: objSlice.rA1.x,
                                y: objSlice.rA1.y,
                                z: botZ
                            },
                            b1 = {
                                x: objSlice.rA2.x,
                                y: objSlice.rA2.y,
                                z: botZ
                            },
                            c1 = {
                                x: objSlice.rB1.x,
                                y: objSlice.rB1.y,
                                z: botZ
                            },
                            d1 = {
                                x: objSlice.rB2.x,
                                y: objSlice.rB2.y,
                                z: botZ
                            };

                        if (topZ < mainLink.height) {
                            wall.polygons3d.sides.push(
                                [
                                    [a.x / 1000, a.z / 1000, a.y / 1000],
                                    [b.x / 1000, b.z / 1000, b.y / 1000],
                                    [c.x / 1000, c.z / 1000, c.y / 1000],
                                    [d.x / 1000, d.z / 1000, d.y / 1000],
                                ]
                            );
                        }

                        if (botZ > 0) {
                            wall.polygons3d.sides.push(
                                [
                                    [a1.x / 1000, a1.z / 1000, a1.y / 1000],
                                    [b1.x / 1000, b1.z / 1000, b1.y / 1000],
                                    [c1.x / 1000, c1.z / 1000, c1.y / 1000],
                                    [d1.x / 1000, d1.z / 1000, d1.y / 1000],
                                ]
                            );
                        }

                        wall.polygons3d.sides.push(
                            [
                                [a.x / 1000, a.z / 1000, a.y / 1000],
                                [b.x / 1000, b.z / 1000, b.y / 1000],
                                [a1.x / 1000, a1.z / 1000, a1.y / 1000],
                                [b1.x / 1000, b1.z / 1000, b1.y / 1000],
                            ]
                        );
                        if (objSlice?.isCorner && objSlice.len1 === 0) wall.polygons3d.sides.pop();

                        wall.polygons3d.sides.push(
                            [
                                [c.x / 1000, c.z / 1000, c.y / 1000],
                                [d.x / 1000, d.z / 1000, d.y / 1000],
                                [c1.x / 1000, c1.z / 1000, c1.y / 1000],
                                [d1.x / 1000, d1.z / 1000, d1.y / 1000],
                            ]
                        );
                        if (objSlice?.isCorner && objSlice.len2 === 0) wall.polygons3d.sides.pop();

                        if (objSlice.isHole && objSlice.depth < (mainLink.depth + mainLink.innerDepth)) {
                            const rA2Inner = objSlice.rA2Inner;
                            const rB2Inner = objSlice.rB2Inner;

                            // Лицевая сторона ниши в цвет стены
                            wall.polygons3d.front.push(
                                [
                                    [rA2Inner.x / 1000, botZ / 1000, rA2Inner.y / 1000],
                                    [rA2Inner.x / 1000, topZ / 1000, rA2Inner.y / 1000],
                                    [rB2Inner.x / 1000, botZ / 1000, rB2Inner.y / 1000],
                                    [rB2Inner.x / 1000, topZ / 1000, rB2Inner.y / 1000]
                                ]
                            );
                        }

                        /* Проверка на пересечение с параллельной стеной */
                        const sliceNodeA = new Node(objSlice.rB1.x, objSlice.rB1.y);
                        const sliceNodeB = new Node(objSlice.rA1.x, objSlice.rA1.y);
                        const sliceCenterV = sliceNodeA.clone().add(sliceNodeB.clone().sub(sliceNodeA).multiplyScalar(0.5));

                        let v = sliceCenterV.clone().sub(sliceNodeB);
                        let rotate = (mainLink.lrBuild === 'left') ? 90 : -90;
                        let __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(10000000);

                        const sliceCenterPerp1 = sliceCenterV.clone();
                        const sliceCenterPerp2 = sliceCenterV.clone();
                        sliceCenterPerp1.x = sliceCenterPerp1.x + __v.x;
                        sliceCenterPerp1.y = sliceCenterPerp1.y + __v.y;
                        sliceCenterPerp2.x = sliceCenterPerp2.x - __v.x;
                        sliceCenterPerp2.y = sliceCenterPerp2.y - __v.y;

                        let crossPointWO = false;
                        wall.pairsWalls.forEach((pairWallIndex, index) => {
                            if (!crossPointWO) {
                                const pairWall = plan.bWalls[pairWallIndex];

                                const pairWallA1 = pairWall.mainLink.a;
                                const pairWallA2 = pairWall.mainLink.b;

                                crossPointWO = getIntersection(
                                    { x: sliceCenterPerp1, y: sliceCenterPerp2 },
                                    { x: pairWallA1, y: pairWallA2 }
                                );
                            }

                        });
                        /* / Проверка на пересечение с параллельной стеной */

                        __y = (objSlice.heightFromFloor * 1 + objSlice.height * 1) / 1000;

                        if (__y < mainLink.height / 1000) {

                            rB1 = objSlice.rA2
                            rB2 = objSlice.rB2

                            if (!crossPointWO) {
                                wall.polygons3d.back.push(
                                    [
                                        [rB1.x / 1000, __y, rB1.y / 1000],
                                        [rB1.x / 1000, mainLink.height / 1000, rB1.y / 1000],
                                        [rB2.x / 1000, __y, rB2.y / 1000],
                                        [rB2.x / 1000, mainLink.height / 1000, rB2.y / 1000],
                                    ]
                                );
                            }

                            rB1 = objSlice.rB1
                            rB2 = objSlice.rA1
                            wall.polygons3d.front.push(
                                [
                                    [rB1.x / 1000, __y, rB1.y / 1000],
                                    [rB1.x / 1000, mainLink.height / 1000, rB1.y / 1000],
                                    [rB2.x / 1000, __y, rB2.y / 1000],
                                    [rB2.x / 1000, mainLink.height / 1000, rB2.y / 1000],
                                ]
                            );
                        }


                        if (objSlice.heightFromFloor > 0) {

                            __y = objSlice.heightFromFloor / 1000

                            rB1 = objSlice.rA2
                            rB2 = objSlice.rB2

                            if (!crossPointWO) {
                                wall.polygons3d.back.push(
                                    [
                                        [rB1.x / 1000, __y, rB1.y / 1000],
                                        [rB1.x / 1000, 0.0, rB1.y / 1000],
                                        [rB2.x / 1000, __y, rB2.y / 1000],
                                        [rB2.x / 1000, 0.0, rB2.y / 1000],
                                    ]
                                );
                            }

                            rB1 = objSlice.rB1
                            rB2 = objSlice.rA1
                            wall.polygons3d.front.push(
                                [
                                    [rB1.x / 1000, __y, rB1.y / 1000],
                                    [rB1.x / 1000, 0.0, rB1.y / 1000],
                                    [rB2.x / 1000, __y, rB2.y / 1000],
                                    [rB2.x / 1000, 0.0, rB2.y / 1000],
                                ]
                            );
                        }
                    });
                }
            });
        };
        const generateWalls = (walls) => {

            // console.log('generateWalls windowModel',windowModel)
            // console.log('generateWalls doorModel',doorModel)
            // console.log('generateWalls walls',walls)

            prepareWalls(walls);

            const result = new THREE.Group();
            result.name = 'walls';
            walls.map((wall) => {
                if (!wall.showModule) return;

                const mainLink = wall.mainLink;
                const wallHeight = mainLink.height;

                const whiteMat = new THREE.MeshPhysicalMaterial({ color: 0xffffff });
                whiteMat.side = THREE.DoubleSide;

                let mainMat, secondaryMat = whiteMat;
                if (wall.materialRGB.rgbColor) {
                    mainMat = wall.material.clone();
                    mainMat.color = new THREE.Color('rgb(' + wall.materialRGB.rgb.r + ',' + wall.materialRGB.rgb.g + ',' + wall.materialRGB.rgb.b + ')')
                } else {
                    mainMat = wall.material.clone();
                }

                const group = new THREE.Group();
                group.name = 'wall';
                group.planObj = wall;

                let geometry, mat;

                if (wall.isBezier) {
                    // Кривые стены
                    const points = wall.polygons3d.mainBezier;
                    const parallel = wall.polygons3d.parallelSide;
                    const outline = wall.polygons3d.outlineBezier;
                    const inline = wall.polygons3d.inlineBezier;
                    const inlinePoints = wall.polygons3d.inlinePoints;

                    const bezierWall = new THREE.Shape();
                    const height = wallHeight / 1000;

                    bezierWall.moveTo(points[0].x, points[0].y);
                    bezierWall.lineTo(parallel[0].x, parallel[0].y);

                    outline.forEach((point) => {
                        bezierWall.lineTo(point[0].x, point[0].y);
                        bezierWall.bezierCurveTo(
                            point[1].x,
                            point[1].y,
                            point[2].x,
                            point[2].y,
                            point[3].x,
                            point[3].y);
                    })

                    // Закрытие стен
                    bezierWall.lineTo(parallel[1].x, parallel[1].y);
                    bezierWall.lineTo(points[3].x, points[3].y);

                    inline.forEach((point) => {
                        bezierWall.lineTo(point[3].x, point[3].y);
                        bezierWall.bezierCurveTo(
                            point[2].x,
                            point[2].y,
                            point[1].x,
                            point[1].y,
                            point[0].x,
                            point[0].y);
                    })

                    const segments = 20;
                    const extrudeSettings = (height) => {
                        return (
                            {
                                curveSegments: segments,
                                steps: 1,
                                depth: height,
                                bevelEnabled: false,
                            })
                    }

                    const geometry = new THREE.ExtrudeGeometry(bezierWall, extrudeSettings(height));

                    const isPointOnSide = (vertex) => {
                        const { x, y } = vertex;
                        return inlinePoints.some((point) => {
                            const epsilon = 0.07;
                            return Math.abs(point.x - x) <= epsilon && Math.abs(point.y - y) <= epsilon;
                        });
                    }

                    geometry.faces.forEach((face) => {
                        const vertexA = geometry.vertices[face.a];
                        const vertexB = geometry.vertices[face.b];
                        const vertexC = geometry.vertices[face.c];

                        if (
                            isPointOnSide(vertexA)
                            && isPointOnSide(vertexB)
                            && isPointOnSide(vertexC)
                            && face.materialIndex === 1
                        ) {
                            face.materialIndex = 1;
                        } else {
                            face.materialIndex = 0;
                        }
                    });

                    mat = mainMat.clone();
                    if (mat.map) {
                        mat.map = mat.map.clone();
                        mat.map.rotation = -90 * (Math.PI / 180);
                        mat.map.needsUpdate = true;
                    }

                    const mesh = new THREE.Mesh(geometry, [secondaryMat, mat]);
                    const wallWithHoles = subtractHoleOnWall(wall, mesh, geometry, segments);

                    wallWithHoles.scale.z *= -1;
                    wallWithHoles.rotateX(90 * Math.PI / 180)

                    group.add(wallWithHoles);
                } else if (wall?.isExtrudeWall) {
                    const mainLink = wall.mainLink;
                    const wallA1 = wall.innerLink.a;
                    const wallA2 = wall.innerLink.b;
                    const wallB1 = wall.parallelLink.a;
                    const wallB2 = wall.parallelLink.b;

                    const wallShape = new THREE.Shape();
                    const height = wallHeight / 1000;

                    wallShape.moveTo(wallA1.x / 1000, wallA1.y / 1000);
                    wallShape.lineTo(wallA2.x / 1000, wallA2.y / 1000);
                    wallShape.lineTo(mainLink.b.x / 1000, mainLink.b.y / 1000);
                    wallShape.lineTo(wallB2.x / 1000, wallB2.y / 1000);
                    wallShape.lineTo(wallB1.x / 1000, wallB1.y / 1000);
                    wallShape.lineTo(mainLink.a.x / 1000, mainLink.a.y / 1000);
                    wallShape.lineTo(wallA1.x / 1000, wallA1.y / 1000);

                    const segments = 20;
                    const extrudeSettings = (height) => {
                        return (
                            {
                                curveSegments: segments,
                                steps: 1,
                                depth: height,
                                bevelEnabled: false,
                            })
                    }

                    const geometry = new THREE.ExtrudeGeometry(wallShape, extrudeSettings(height));

                    const isPointOnLine = (vertex, epsilon = 0.5) => {
                        const { x, y } = vertex;
                        const crossProduct = ((wallA2.x - wallA1.x) / 1000) * (y - wallA1.y / 1000) - (wallA2.y - wallA1.y) / 1000 * (x - wallA1.x / 1000);

                        return Math.abs(crossProduct) < epsilon;
                    };

                    geometry.faces.forEach((face) => {
                        const vertexA = geometry.vertices[face.a];
                        const vertexB = geometry.vertices[face.b];
                        const vertexC = geometry.vertices[face.c];

                        if (
                            isPointOnLine(vertexA)
                            && isPointOnLine(vertexB)
                            && isPointOnLine(vertexC)
                            && face.materialIndex === 1
                        ) {
                            face.materialIndex = 1;
                        } else {
                            face.materialIndex = 0;
                        }
                    });

                    mat = mainMat.clone();
                    if (mat.map) {
                        mat.map = mat.map.clone();
                        mat.map.rotation = -90 * (Math.PI / 180);
                        mat.map.needsUpdate = true;
                    }

                    const mesh = new THREE.Mesh(geometry, [secondaryMat, mat]);
                    const wallWithHoles = subtractHoleOnWall(wall, mesh, geometry, segments);

                    wallWithHoles.scale.z *= -1;
                    wallWithHoles.rotateX(90 * Math.PI / 180)

                    group.add(wallWithHoles);
                } else {
                    // Лицевая сторона
                    wall.polygons3d.front.forEach(points => {
                        geometry = setPolygonFace(points);

                        let height = Math.abs(points[1][1] - points[0][1]);
                        if (height === 0) {
                            height = Math.abs(points[1][1] - points[2][1]);
                        }

                        let n1 = new Node(points[1][0], points[1][2]);
                        let n2 = new Node(points[2][0], points[2][2]);
                        let width = Math.sqrt((n1.x - n2.x) * (n1.x - n2.x) + (n1.y - n2.y) * (n1.y - n2.y));

                        mat = mainMat.clone();
                        if (mat.map) {
                            mat.map = mat.map.clone();
                            mat.map.rotation = -90 * (Math.PI / 180);
                            mat.map.repeat.set(width, height);
                            mat.map.needsUpdate = true;
                        }
                        if (height > 0)
                            group.add(new THREE.Mesh(geometry, mat));
                    });

                    // Задняя сторона
                    wall.polygons3d.back.forEach(points => {
                        geometry = setPolygonFace(points);
                        mat = secondaryMat.clone();
                        group.add(new THREE.Mesh(geometry, mat));
                    });

                    // Боковушки, откосы
                    wall.polygons3d.sides.forEach(points => {
                        geometry = setPolygonFace(points);
                        mat = whiteMat.clone();
                        group.add(new THREE.Mesh(geometry, mat));
                    });
                }

                result.add(group);

                if (centerModule === wall) {
                    const bb = new THREE.Box3();
                    bb.setFromObject(group);
                    bb.center(orbitControls.target);
                    camera.updateProjectionMatrix();
                    orbitControls.update();
                }

            });
            return result;
        };

        const generateColumns = (columns) => {
            const result = new THREE.Group();
            result.name = "Columns";

            columns.map((column) => {
                if (!column.showModule) return null;

                const group = new THREE.Group();

                const wallHeight = column.height / 1000;
                const maxHeight = column.maxHeight / 1000;
                const heightFromFloor = column.type === 'roof' && maxHeight
                    ? ((maxHeight || wallHeight) - wallHeight) : 0;
                let wallLength, wallDepth, angelFix;
                if (column.width >= column.depth) {
                    // horizontal
                    wallLength = column.width;
                    wallDepth = column.depth;
                    angelFix = 0;
                } else if (column.depth > column.width) {
                    // vertical
                    wallLength = column.depth;
                    wallDepth = column.width;
                    angelFix = 90 * (Math.PI / 180);
                }

                const whiteMat = new THREE.MeshPhongMaterial({ color: 0xffffff });
                whiteMat.side = THREE.DoubleSide;

                let materialFaceL;
                let materialFaceT;
                let materialFaceR;
                let materialFaceB;
                if (column.leftSide) {
                    materialFaceL = new THREE.MeshPhongMaterial();
                    materialFaceL.side = THREE.DoubleSide;

                    if (column.leftSideRGB.rgbColor) {
                        materialFaceL.color = new THREE.Color(
                            "rgb(" +
                            column.leftSideRGB.rgb.r +
                            "," +
                            column.leftSideRGB.rgb.g +
                            "," +
                            column.leftSideRGB.rgb.b +
                            ")"
                        );
                    } else {
                        materialFaceL.copy(column.leftSide);
                        materialFaceL.map = column.leftSide.map.clone();
                        materialFaceL.map.repeat.set(0.25, 0.25);

                        materialFaceL.map.needsUpdate = true;
                    }
                }
                if (column.topSide) {
                    materialFaceT = new THREE.MeshPhongMaterial();
                    materialFaceT.side = THREE.DoubleSide;

                    if (column.topSideRGB.rgbColor) {
                        materialFaceT.color = new THREE.Color(
                            "rgb(" +
                            column.topSideRGB.rgb.r +
                            "," +
                            column.topSideRGB.rgb.g +
                            "," +
                            column.topSideRGB.rgb.b +
                            ")"
                        );
                    } else {
                        materialFaceT.copy(column.topSide);
                        materialFaceT.map = column.topSide.map.clone();
                        materialFaceT.map.repeat.set(0.25, 0.25);

                        materialFaceT.map.needsUpdate = true;
                    }
                }
                if (column.rightSide) {
                    materialFaceR = new THREE.MeshPhongMaterial();
                    materialFaceR.side = THREE.DoubleSide;

                    if (column.rightSideRGB.rgbColor) {
                        materialFaceR.color = new THREE.Color(
                            "rgb(" +
                            column.rightSideRGB.rgb.r +
                            "," +
                            column.rightSideRGB.rgb.g +
                            "," +
                            column.rightSideRGB.rgb.b +
                            ")"
                        );
                    } else {
                        materialFaceR.copy(column.rightSide);
                        materialFaceR.map = column.rightSide.map.clone();
                        materialFaceR.map.repeat.set(0.25, 0.25);

                        materialFaceR.map.needsUpdate = true;
                    }
                }
                if (column.bottomSide) {
                    materialFaceB = new THREE.MeshPhongMaterial();
                    materialFaceB.side = THREE.DoubleSide;

                    if (column.bottomSideRGB.rgbColor) {
                        materialFaceB.color = new THREE.Color(
                            "rgb(" +
                            column.bottomSideRGB.rgb.r +
                            "," +
                            column.bottomSideRGB.rgb.g +
                            "," +
                            column.bottomSideRGB.rgb.b +
                            ")"
                        );
                    } else {
                        materialFaceB.copy(column.bottomSide);
                        materialFaceB.map = column.bottomSide.map.clone();
                        materialFaceB.map.repeat.set(0.25, 0.25);

                        materialFaceB.map.needsUpdate = true;
                    }
                }

                const pointA = new Vector2(column.x, column.y);
                const pointB = new Vector2(column.x, column.y);
                const v = new Vector2(column.x, column.y)
                    .normalize()
                    .rotateAround(
                        new Vector2(0, 0),
                        -column.angle - new Vector2(column.x, column.y).angle() - angelFix
                    )
                    .setLength(wallLength / 2);

                pointA.x = pointA.x + v.x;
                pointA.y = pointA.y + v.y;
                pointB.x = pointB.x - v.x;
                pointB.y = pointB.y - v.y;

                // Нарезка стены под окна/двери
                const wallSlicesPoints = [];

                wallSlicesPoints.sort((a, b) => {
                    return a.pos - b.pos;
                });

                let wall_rA1, wall_rA2, wall_rB1, wall_rB2;
                wall_rA1 = column.points[0];
                wall_rA2 = column.points[1];
                wall_rB1 = column.points[3];
                wall_rB2 = column.points[2];

                let __y,
                    rB1,
                    rB2,
                    geometry,
                    mat;
                const side3slopesGeometry = new THREE.Group(); /* Откосы */

                if (wallSlicesPoints.length > 0) {
                    for (let i = 0; i < wallSlicesPoints.length; i++) {
                        /* Откосы */
                        const topZ =
                            wallSlicesPoints[i].heightFromFloor * 1 +
                            wallSlicesPoints[i].height * 1;
                        const botZ =
                            wallSlicesPoints[i].heightFromFloor > 0
                                ? wallSlicesPoints[i].heightFromFloor
                                : 0;
                        let a = {
                            x: wallSlicesPoints[i].rA1.x,
                            y: wallSlicesPoints[i].rA1.y,
                            z: topZ,
                        },
                            b = {
                                x: wallSlicesPoints[i].rA2.x,
                                y: wallSlicesPoints[i].rA2.y,
                                z: topZ,
                            },
                            c = {
                                x: wallSlicesPoints[i].rB1.x,
                                y: wallSlicesPoints[i].rB1.y,
                                z: topZ,
                            },
                            d = {
                                x: wallSlicesPoints[i].rB2.x,
                                y: wallSlicesPoints[i].rB2.y,
                                z: topZ,
                            },
                            a1 = {
                                x: wallSlicesPoints[i].rA1.x,
                                y: wallSlicesPoints[i].rA1.y,
                                z: botZ,
                            },
                            b1 = {
                                x: wallSlicesPoints[i].rA2.x,
                                y: wallSlicesPoints[i].rA2.y,
                                z: botZ,
                            },
                            c1 = {
                                x: wallSlicesPoints[i].rB1.x,
                                y: wallSlicesPoints[i].rB1.y,
                                z: botZ,
                            },
                            d1 = {
                                x: wallSlicesPoints[i].rB2.x,
                                y: wallSlicesPoints[i].rB2.y,
                                z: botZ,
                            };

                        if (i === 0) {
                            mat = materialFaceL.clone();
                            if (mat.map) {
                                mat.map = mat.map.clone();
                                mat.map.rotation = -90 * (Math.PI / 180);
                                mat.map.repeat.set(wallSlicesPoints[i].pos / 270, wallHeight / 270);
                                mat.map.needsUpdate = true;
                            }
                            group.add(
                                new THREE.Mesh(
                                    setSide(wall_rB1, wallSlicesPoints[i].rA2, wallHeight),
                                    mat
                                )
                            );

                            mat = materialFaceR.clone();
                            if (mat.map) {
                                mat.map = mat.map.clone();
                                mat.map.rotation = -90 * (Math.PI / 180);
                                mat.map.repeat.set(wallSlicesPoints[i].pos / 270, wallHeight / 270);
                                mat.map.needsUpdate = true;
                            }
                            group.add(
                                new THREE.Mesh(
                                    setSide(wallSlicesPoints[i].rA1, wall_rA1, wallHeight),
                                    mat
                                )
                            );
                        }

                        if (i === wallSlicesPoints.length - 1) {
                            mat = materialFaceL.clone();
                            if (mat.map) {
                                mat.map = mat.map.clone();
                                mat.map.rotation = -90 * (Math.PI / 180);
                                mat.map.repeat.set(
                                    (wallLength -
                                        wallSlicesPoints[i].pos -
                                        wallSlicesPoints[i].width) /
                                    270,
                                    wallHeight / 270
                                );
                                mat.map.needsUpdate = true;
                            }
                            group.add(
                                new THREE.Mesh(
                                    setSide(wallSlicesPoints[i].rB2, wall_rB2, wallHeight),
                                    mat
                                )
                            );

                            mat = materialFaceR.clone();
                            if (mat.map) {
                                mat.map = mat.map.clone();
                                mat.map.rotation = -90 * (Math.PI / 180);
                                mat.map.repeat.set(
                                    (wallLength -
                                        wallSlicesPoints[i].pos -
                                        wallSlicesPoints[i].width) /
                                    270,
                                    wallHeight / 270
                                );
                                mat.map.needsUpdate = true;
                            }
                            group.add(
                                new THREE.Mesh(
                                    setSide(wall_rA2, wallSlicesPoints[i].rB1, wallHeight),
                                    mat
                                )
                            );
                        } else {
                            mat = materialFaceL.clone();
                            if (mat.map) {
                                mat.map = mat.map.clone();
                                mat.map.rotation = -90 * (Math.PI / 180);
                                mat.map.repeat.set(
                                    (wallSlicesPoints[i + 1].pos -
                                        wallSlicesPoints[i].pos -
                                        wallSlicesPoints[i].width) /
                                    270,
                                    wallHeight / 270
                                );
                                mat.map.needsUpdate = true;
                            }
                            group.add(
                                new THREE.Mesh(
                                    setSide(
                                        wallSlicesPoints[i].rB2,
                                        wallSlicesPoints[i + 1].rA2,
                                        wallHeight
                                    ),
                                    mat
                                )
                            );

                            mat = materialFaceR.clone();
                            if (mat.map) {
                                mat.map = mat.map.clone();
                                mat.map.rotation = -90 * (Math.PI / 180);
                                mat.map.repeat.set(
                                    (wallSlicesPoints[i + 1].pos -
                                        wallSlicesPoints[i].pos -
                                        wallSlicesPoints[i].width) /
                                    270,
                                    wallHeight / 270
                                );
                                mat.map.needsUpdate = true;
                            }
                            group.add(
                                new THREE.Mesh(
                                    setSide(
                                        wallSlicesPoints[i + 1].rA1,
                                        wallSlicesPoints[i].rB1,
                                        wallHeight
                                    ),
                                    mat
                                )
                            );
                        }

                        __y =
                            (wallSlicesPoints[i].heightFromFloor * 1 +
                                wallSlicesPoints[i].height * 1) /
                            100;

                        if (__y < wallHeight) {
                            rB1 = wallSlicesPoints[i].rA2;
                            rB2 = wallSlicesPoints[i].rB2;

                            geometry = setPolygonFace([
                                [rB1.x / 10000, __y, rB1.y / 10000],
                                [rB2.x / 10000, __y, rB2.y / 10000],
                                [rB1.x / 10000, wallHeight, rB1.y / 10000],
                                [rB2.x / 10000, wallHeight, rB2.y / 10000]
                            ]);
                            mat = materialFaceL.clone();
                            if (mat.map) {
                                mat.map = mat.map.clone();
                                if (wallSlicesPoints[i].heightFromFloor === 0)
                                    mat.map.rotation = -90 * (Math.PI / 180);
                                mat.map.repeat.set(
                                    wallSlicesPoints[i].width / 270,
                                    (wallHeight -
                                        wallSlicesPoints[i].height -
                                        wallSlicesPoints[i].heightFromFloor) /
                                    270
                                );
                                mat.map.needsUpdate = true;
                            }
                            group.add(new THREE.Mesh(geometry, mat));

                            rB1 = wallSlicesPoints[i].rB1;
                            rB2 = wallSlicesPoints[i].rA1;

                            geometry = setPolygonFace([
                                [rB1.x / 10000, __y, rB1.y / 10000],
                                [rB2.x / 10000, __y, rB2.y / 10000],
                                [rB1.x / 1000, wallHeight, rB1.y / 1000],
                                [rB2.x / 1000, wallHeight, rB2.y / 1000]
                            ]);
                            mat = materialFaceR.clone();
                            if (mat.map) {
                                mat.map = mat.map.clone();
                                if (wallSlicesPoints[i].heightFromFloor === 0)
                                    mat.map.rotation = -90 * (Math.PI / 180);
                                mat.map.repeat.set(
                                    wallSlicesPoints[i].width / 270,
                                    (wallHeight -
                                        wallSlicesPoints[i].height -
                                        wallSlicesPoints[i].heightFromFloor) /
                                    270
                                );
                                mat.map.needsUpdate = true;
                            }
                            group.add(new THREE.Mesh(geometry, mat));
                        }

                        if (wallSlicesPoints[i].heightFromFloor > 0) {
                            __y = wallSlicesPoints[i].heightFromFloor / 1000;

                            rB1 = wallSlicesPoints[i].rA2;
                            rB2 = wallSlicesPoints[i].rB2;

                            geometry = setPolygonFace([
                                [rB1.x / 1000, __y, rB1.y / 1000],
                                [rB2.x / 1000, __y, rB2.y / 1000],
                                [rB1.x / 1000, 0.0, rB1.y / 1000],
                                [rB2.x / 1000, 0.0, rB2.y / 1000]
                            ]);
                            mat = materialFaceL.clone();
                            if (mat.map) {
                                mat.map = mat.map.clone();
                                // mat.map.rotation = -90*(Math.PI/180)
                                mat.map.repeat.set(
                                    wallSlicesPoints[i].width / 270,
                                    wallSlicesPoints[i].heightFromFloor / 270
                                );
                                mat.map.needsUpdate = true;
                            }
                            group.add(new THREE.Mesh(geometry, mat));

                            rB1 = wallSlicesPoints[i].rB1;
                            rB2 = wallSlicesPoints[i].rA1;

                            geometry = setPolygonFace([
                                [rB1.x / 1000, __y, rB1.y / 1000],
                                [rB2.x / 1000, __y, rB2.y / 1000],
                                [rB1.x / 1000, 0.0, rB1.y / 1000],
                                [rB2.x / 1000, 0.0, rB2.y / 1000]
                            ]);
                            mat = materialFaceR.clone();
                            if (mat.map) {
                                mat.map = mat.map.clone();
                                // mat.map.rotation = -90*(Math.PI/180)
                                mat.map.repeat.set(
                                    wallSlicesPoints[i].width / 270,
                                    wallSlicesPoints[i].heightFromFloor / 270
                                );
                                mat.map.needsUpdate = true;
                            }
                            group.add(new THREE.Mesh(geometry, mat));
                        }
                    }
                } else {
                    geometry = setPolygonFace([
                        [wall_rB1.x / 1000, 0.0, wall_rB1.y / 1000],
                        [wall_rB1.x / 1000, wallHeight, wall_rB1.y / 1000],
                        [wall_rB2.x / 1000, 0.0, wall_rB2.y / 1000],
                        [wall_rB2.x / 1000, wallHeight, wall_rB2.y / 1000]
                    ]);
                    mat = materialFaceL.clone();
                    if (mat.map) {
                        mat.map = mat.map.clone();
                        mat.map.rotation = -90 * (Math.PI / 180);
                        // mat.map.repeat.set(wallLength/270, wallHeight/270);
                        mat.map.repeat.set(1, 1);
                        mat.map.needsUpdate = true;
                    }
                    group.add(new THREE.Mesh(geometry, mat));

                    geometry = setPolygonFace([
                        [wall_rA1.x / 1000, 0.0, wall_rA1.y / 1000],
                        [wall_rA1.x / 1000, wallHeight, wall_rA1.y / 1000],
                        [wall_rA2.x / 1000, 0.0, wall_rA2.y / 1000],
                        [wall_rA2.x / 1000, wallHeight, wall_rA2.y / 1000]
                    ]);
                    mat = materialFaceR.clone();
                    if (mat.map) {
                        mat.map = mat.map.clone();
                        mat.map.rotation = -90 * (Math.PI / 180);
                        // mat.map.repeat.set(wallLength/270, wallHeight/270);
                        mat.map.repeat.set(1, 1);
                        mat.map.needsUpdate = true;
                    }
                    group.add(new THREE.Mesh(geometry, mat));
                }


                geometry = setPolygonFace([
                    [wall_rB1.x / 1000, 0.0, wall_rB1.y / 1000],
                    [wall_rB1.x / 1000, wallHeight, wall_rB1.y / 1000],
                    [wall_rA1.x / 1000, 0.0, wall_rA1.y / 1000],
                    [wall_rA1.x / 1000, wallHeight, wall_rA1.y / 1000]
                ]);
                mat = materialFaceT.clone();
                if (mat.map) {
                    mat.map = mat.map.clone();
                    mat.map.rotation = -90 * (Math.PI / 180);
                    // mat.map.repeat.set(wallLength/270, wallHeight/270);
                    mat.map.repeat.set(1, 1);
                    mat.map.needsUpdate = true;
                }
                setSideEdging({
                    wallPoints: [
                        [wall_rA1.x / 1000, wallHeight, wall_rA1.y / 1000],
                        [wall_rB1.x / 1000, wallHeight, wall_rB1.y / 1000],
                        [wall_rA1.x / 1000, 0.0, wall_rA1.y / 1000],
                        [wall_rB1.x / 1000, 0.0, wall_rB1.y / 1000],
                    ], wallSlicesPoints, material: mat, group
                })
                // group.add(new Mesh(geometry, mat)); 1

                geometry = setPolygonFace([
                    [wall_rA2.x / 1000, 0.0, wall_rA2.y / 1000],
                    [wall_rA2.x / 1000, wallHeight, wall_rA2.y / 1000],
                    [wall_rB2.x / 1000, 0.0, wall_rB2.y / 1000],
                    [wall_rB2.x / 1000, wallHeight, wall_rB2.y / 1000]
                ]);
                mat = materialFaceB.clone();
                if (mat.map) {
                    mat.map = mat.map.clone();
                    mat.map.rotation = -90 * (Math.PI / 180);
                    // mat.map.repeat.set(wallLength/270, wallHeight/270);
                    mat.map.repeat.set(1, 1);
                    mat.map.needsUpdate = true;
                }
                setSideEdging({
                    wallPoints: [
                        [wall_rA2.x / 1000, wallHeight, wall_rA2.y / 1000],
                        [wall_rB2.x / 1000, wallHeight, wall_rB2.y / 1000],
                        [wall_rA2.x / 1000, 0.0, wall_rA2.y / 1000],
                        [wall_rB2.x / 1000, 0.0, wall_rB2.y / 1000],
                    ], wallSlicesPoints, material: mat, group
                })
                // group.add(new Mesh(geometry, mat)); 2

                //////////////////////////////////////////////////////////////////////////////////////////////////
                geometry = setPolygonFace([ //верхняя часть
                    [wall_rA2.x / 1000, wallHeight, wall_rA2.y / 1000],
                    [wall_rB2.x / 1000, wallHeight, wall_rB2.y / 1000],
                    [wall_rA1.x / 1000, wallHeight, wall_rA1.y / 1000],
                    [wall_rB1.x / 1000, wallHeight, wall_rB1.y / 1000]
                ]);
                group.add(new THREE.Mesh(geometry.clone(), whiteMat)); //верхняя часть

                group.translateY(heightFromFloor);
                group.receiveShadow = true;
                group.name = "column";
                group.planObj = column;
                result.add(group);
            });

            return result;
        };

        const init = () => {
            scene = new THREE.Scene();
            camera = new THREE.PerspectiveCamera(
                40, canvas.current.clientWidth / canvas.current.clientHeight, 0.01, 200);


            clock = new THREE.Clock();

            // raycaster = new THREE.Raycaster();
            renderer = new THREE.WebGLRenderer({
                canvas: canvas.current,
                antialias: true,
                powerPreference: 'high-performance',
                preserveDrawingBuffer: true,
                alpha: true
            });
            renderer.setSize(canvas.current.clientWidth, canvas.current.clientHeight);

            renderer.setClearColor(0x000000, 0)
            renderer.toneMappingExposure = .8
            renderer.physicallyCorrectLights = false

            const GammaEncoding = 3007;
            const PCFSoftShadowMap = 2;
            const ACESFilmicToneMapping = 4;
            renderer.autoClear = true;
            renderer.outputEncoding = GammaEncoding;
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.type = PCFSoftShadowMap;
            renderer.shadowMap.autoUpdate = true;
            renderer.setPixelRatio(window.devicePixelRatio);
            scene.add(new THREE.AmbientLight(0xffffff, .6));


            const directionalLight = new THREE.DirectionalLight(0xffffff, .1);
            directionalLight.position.set(-5, 10, 15).normalize();
            scene.add(directionalLight);

            const directionalLight2 = new THREE.DirectionalLight(0xffffff, .3);
            directionalLight2.position.set(-10, 10, -10).normalize();
            scene.add(directionalLight2);

            const directionalLight3 = new THREE.DirectionalLight(0xffffff, .3);
            directionalLight3.position.set(-10, 10, 10).normalize();
            scene.add(directionalLight3);


            generateGroups();

            if (cameraMode === 0) {
                const box = new THREE.Box3();
                box.setFromObject(scene);

                const size = new THREE.Vector3();
                const center = new THREE.Vector3();
                box.getSize(size);
                box.getCenter(center);
                orbitControls = new OrbitControls(camera, canvas.current);

                const distance = Math.max(size.x, size.z) * 1.5;
                camera.position.set(center.x, center.y + distance, center.z);
                camera.lookAt(center);

                orbitControls.minDistance = 4;
                orbitControls.maxDistance = 100;
                orbitControls.target = center;
                orbitControls.update();
            } else {
                camera.position.set(0, player.height, -5);
                camera.lookAt(new THREE.Vector3(0, player.height, 0));
                camera.fov = 60;

                pointer = new PointerLockControls(camera, canvas.current);
                pointer.lock();

                const PlaneGeometry = new THREE.PlaneGeometry(100, 100);
                const PlaneMaterial = new THREE.MeshPhongMaterial({ color: "white", wireframe: false });
                const Plane = new THREE.Mesh(PlaneGeometry, PlaneMaterial);

                Plane.rotation.x -= Math.PI / 2;
                Plane.scale.x = 3;
                Plane.scale.y = 3;
                Plane.position.y = -0.1;
                Plane.receiveShadow = true;
                scene.add(Plane);
            }
            // console.log('scene',scene)
            onResize();

            if (getFileType === 'getHugeImage')
                sendGetHugeImage(document.querySelector('#scene'));
            if (getFileType === 'getCollada')
                sendGetCollada(document.querySelector('#scene'));
            if (getFileType === 'getGLTF')
                sendGetGLTF(document.querySelector('#scene'));
            if (getFileType === 'getOBJ')
                sendGetOBJ(document.querySelector('#scene'));

            dispatch(projectState.setGetFileType(''));


            /*
            var grid = new THREE.Group();
            var grid2 = new THREE.Group();
            var grid3 = new THREE.Group();
            grid.add(grid2);
            grid2.add(grid3);
            scene.add(grid);

            var sphere = new THREE.Mesh(new THREE.SphereGeometry(1, 32, 24), new THREE.MeshBasicMaterial({
                color: "red",
                wireframe: false
            }));
            sphere.position.set(-1, 1, 0);
            grid3.add(sphere);

            var cube = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1, 2, 2, 2), new THREE.MeshBasicMaterial({
                color: "blue",
                wireframe: true
            }));
            cube.position.set(1, 1, 0);
            grid3.add(cube);

            dragControls = new DragControls([grid], camera, canvas.current);
            dragControls.transformGroup = true;
            dragControls.addEventListener('dragstart', function(event) {
                orbitControls.enabled = false;

                console.log('dragControls',dragControls)
                console.log('dragControls',dragControls.getObjects())
            });
            dragControls.addEventListener('dragend', function(event) {
                orbitControls.enabled = true;
            });
            */
        };

        const generateGroups = () => {
            const columns = plan.columns;
            let cycles, walls
            if (tokenROLE === 'editor' && plan.cycleActive > -1) {
                cycles = plan.cycles.filter((el, index) => index === plan.cycleActive)
                walls = plan.bWalls.filter(wall => {
                    const _points = cycles[0]._points

                    let pointA = _points.filter(point => Math.round(point.x) === wall.mainLink.a.x && Math.round(point.y) === wall.mainLink.a.y)
                    let pointB = _points.filter(point => Math.round(point.x) === wall.mainLink.b.x && Math.round(point.y) === wall.mainLink.b.y)

                    return pointA.length > 0 && pointB.length > 0
                })
            } else {
                cycles = plan.cycles
                walls = plan.bWalls
            }

            // Рисование первого уровня пола/потолка
            // if (filters.floors)
            scene.add(generateFloor(cycles, walls));
            // if (filters.roof)
            scene.add(generateRoof(cycles, walls));


            scene.add(generateWalls(walls));

            scene.add(generateColumns(columns));


            const modulesGroup = new THREE.Group();
            modulesGroup.name = 'Modules';
            const objects = [];
            modules.map(m => {
                let showModule = m.showModule
                if (tokenROLE === 'editor' && plan.cycleActive > -1) {
                    showModule = polyPoint(cycles[0]._points, m.position.x, m.position.y)
                }

                if (showModule) {
                    //modulesGroup.attach(m.model);
                    // console.log('TEST ',m);
                    const rotateGroup = new THREE.Group();
                    rotateGroup.name = 'modelGroup';
                    const translateGroup = new THREE.Group();
                    translateGroup.add(rotateGroup);

                    rotateGroup.add(m.model.clone());

                    modulesGroup.add(translateGroup);

                    translateGroup.position.set(
                        m.position.x / 1000,
                        m.heightFromFloor / 1000,
                        m.position.y / 1000
                    );

                    rotateGroup.rotation.y = m.angle;

                    translateGroup.planObj = m;

                    const dragControls = new DragControls([translateGroup], camera, renderer.domElement);
                    dragControls.transformGroup = true;

                    tool === "move" && cameraMode !== 1 ? dragControls.activate() : dragControls.deactivate();

                    const dragStart = () => {
                        orbitControls.enabled = false;
                        enableSelection = false;
                    }

                    const drag = (event) => {
                        event.object.position.y = event.object.planObj.heightFromFloor * 1 / 1000;
                    }

                    const dragEnd = (event) => {
                        orbitControls.enabled = true;
                        enableSelection = true;

                        event.object.planObj.position.x = event.object.position.x * 1000;
                        event.object.planObj.position.y = event.object.position.z * 1000;

                        event.object.updateMatrix();
                    }

                    dragControls.addEventListener('dragstart', dragStart);
                    dragControls.addEventListener('drag', drag);
                    dragControls.addEventListener('dragend', dragEnd);

                    if (centerModule === m) {
                        const bb = new THREE.Box3();
                        bb.setFromObject(translateGroup);
                        bb.center(orbitControls.target);
                        camera.updateProjectionMatrix();
                        orbitControls.update();
                    }
                }
            })
            scene.add(modulesGroup);

            scene.add(setDoors());
            scene.add(setWindows());

            scene.add(setElectricSocket());
            scene.add(setSwitch());
            scene.add(setOutletElectricalWire());
            scene.add(setHeatingBattery());
            scene.add(setElectricPanel());
            scene.add(setRedCube());
        };

        const animate = () => {
            if (cameraMode === 1) update();

            requestAnimationFrame(animate);
            //scene.overrideMaterial = true;
            const mixerUpdateDelta = clock.getDelta();
            modules.map(m => m.mixer.update(mixerUpdateDelta));
            const camVector = new THREE.Vector3(0, 0, -1).applyQuaternion(camera.quaternion).normalize();
            if (filters.transparent) {
                if (camVector.y < -0.5) {
                    scene.traverse(obj => {
                        if (obj.isGroup && (obj.name === 'walls' || obj.name === 'Columns')) {
                            obj.traverse(el => {
                                if (el.isMesh && (el.parent.name !== 'wall' || el.parent.name !== 'Columns')) {
                                    if (el.material.length > 0) {
                                        el.material.map(m => {
                                            m.transparent = true;
                                            m.opacity = 1;
                                        })
                                    } else {
                                        el.material.transparent = true;
                                        el.material.opacity = 1;
                                    }
                                } else if (el.isMesh && (el.parent.name === 'wall' || el.parent.name === 'Columns')) {
                                    if (el.material.length > 0) {
                                        el.material.map(m => {
                                            m.transparent = true;
                                            m.opacity = 1;
                                        })
                                    } else {
                                        el.material.transparent = true;
                                        el.material.opacity = 1;
                                    }
                                }
                            })
                        }
                    });
                }
                else {
                    scene.traverse(obj => {
                        if (obj.isGroup && (obj.name === 'walls' || obj.name === 'Columns')) {
                            obj.traverse(el => {
                                if (el.isMesh && (el.parent.name !== 'wall' || el.parent.name !== 'Columns')) {
                                    if (el.material.length > 0) {
                                        el.material.map(m => {
                                            m.transparent = true;
                                            m.opacity = 0.2;
                                        })
                                    } else {
                                        el.material.transparent = true;
                                        el.material.opacity = 0.2;
                                    }
                                } else if (el.isMesh && (el.parent.name === 'wall' || el.parent.name === 'Columns')) {
                                    if (el.material.length > 0) {
                                        el.material.map(m => {
                                            m.transparent = true;
                                            m.opacity = 0.2;
                                        })
                                    } else {
                                        el.material.transparent = true;
                                        el.material.opacity = 0.2;
                                    }
                                }
                            })
                        }
                    });
                }
            }

            scene.children.map(obj => {
                if (obj.name === 'Floor')
                    obj.visible = filters.floors;
                else if (obj.name === 'Roof')
                    obj.visible = filters.roof;
                else if (obj.name === 'walls' || obj.name === 'Windows' || obj.name === 'Doors' || obj.name === 'Columns') {
                    obj.visible = filters.walls;
                }
                else if (obj.name === 'Modules')
                    obj.visible = filters.furniture;
            })

            if (mouse && enableSelection && tool === "move") {
                raycaster.setFromCamera(mouse, camera);
                const intersects = raycaster.intersectObjects(scene.children, true);

                if (intersects.length > 0 && !mouseIsOut) {

                    // console.log('intersects[0]',intersects[0])
                    let isModule = null

                    if (INTERSECTED) {
                        if (INTERSECTED.name === 'floor' || INTERSECTED.name === 'roof') {
                            if (INTERSECTED.material.length > 0) {
                                INTERSECTED.material.map(m => {
                                    m.emissive.setHex(0);
                                })
                            } else {
                                INTERSECTED.material.emissive.setHex(0);
                            }
                        } else if (INTERSECTED.parent.name === 'wall' || intersects[0].object.parent.name === 'Columns') {
                            INTERSECTED.parent.children.map(obj => {
                                if (obj.material.length > 0) {
                                    obj.material.map(m => {
                                        m.emissive.setHex(0);
                                    })
                                } else {
                                    obj.material.emissive.setHex(0);
                                }
                            })
                        }
                    }

                    if (intersects[0].object.name === 'floor' || intersects[0].object.name === 'roof') {
                        INTERSECTED = intersects[0].object;
                        if (INTERSECTED.material.length > 0) {
                            INTERSECTED.material.map(m => {
                                m.emissive.setHex(0x59DA28);
                            })
                        } else {
                            INTERSECTED.material.emissive.setHex(0x59DA28);
                        }
                    } else if (intersects[0].object.parent.name === 'wall' || intersects[0].object.parent.name === 'Columns') {
                        INTERSECTED = intersects[0].object;
                        INTERSECTED.parent.children.map(obj => {
                            if (obj.material.length > 0) {
                                obj.material.map(m => {
                                    m.emissive.setHex(0x59DA28);
                                })
                            } else {
                                obj.material.emissive.setHex(0x59DA28);
                            }
                        })
                    } else if (isModule) {
                        INTERSECTED = isModule;
                        // console.log('INTERSECTED',INTERSECTED)
                        // console.log('isModule',isModule)
                    }
                    // }

                } else {

                    if (INTERSECTED) {
                        if (INTERSECTED.name === 'floor' || INTERSECTED.name === 'roof') {
                            if (INTERSECTED.material.length > 0) {
                                INTERSECTED.material.map(m => {
                                    m.emissive.setHex(0);
                                })
                            } else {
                                INTERSECTED.material.emissive.setHex(0);
                            }
                        } else if (INTERSECTED.parent.name === 'wall') {
                            INTERSECTED.parent.children.map(obj => {
                                if (obj.material.length > 0) {
                                    obj.material.map(m => {
                                        m.emissive.setHex(0);
                                    })
                                } else {
                                    obj.material.emissive.setHex(0);
                                }
                            })
                        }
                    }

                    INTERSECTED = null;

                }
            }

            if (vnh) vnh.update();

            renderer.render(scene, camera);
            // composer.render();
            // console.log('camera.position ---',camera.position)
        }

        function update() {
            control();
            ixMovementUpdate();
        }

        function ixMovementUpdate() {
            player.velocity += player.gravity;
            camera.position.y -= player.velocity;

            if (camera.position.y < player.height) {
                camera.position.y = player.height;
                player.jumps = false;
            }
        }

        function control() {
            if (controls[87] || controls[38]) { // w
                pointer.moveForward(0.2);
            }
            if (controls[83] || controls[40]) { // s
                pointer.moveForward(-0.2);
            }
            if (controls[65] || controls[37]) { // a
                pointer.moveRight(-0.2);
            }
            if (controls[68] || controls[39]) { // d
                pointer.moveRight(0.2);
            }
        }

        //ГРУЗИМ МОДЕЛЬ ОКНА
        new THREE.TextureLoader().load(window.confComponentUrl + '/assets/objects/window/white.jpg', texture => {
            new THREE.TextureLoader().load(window.confComponentUrl + '/assets/objects/window/aomap.png', aomap => {
                new FBXLoader().load(window.confComponentUrl + '/assets/objects/window/window.fbx', (window) => {
                    window.traverse(o => {
                        if (o.isMesh) {
                            o.material.map(m => {
                                if (/glass/.test(m.name)) {
                                    m.opacity = 0.4;
                                    m.color = new THREE.Color(0xCCE5FF);
                                    m.map = null;
                                    m.aoMap = null;
                                }
                                else {
                                    m.map = texture;
                                    m.aoMap = aomap;
                                }
                            });
                        }
                    });
                    windowModel = window;
                    // console.log('windowModel',windowModel)
                    scene.add(setWindows());
                });
            });
        });

        //ГРУЗИМ МОДЕЛЬ ДВЕРИ
        new THREE.TextureLoader().load(window.confComponentUrl + '/assets/objects/door/white.jpg', texture => {
            new THREE.TextureLoader().load(window.confComponentUrl + '/assets/objects/door/aomap.png', aomap => {
                new FBXLoader().load(window.confComponentUrl + '/assets/objects/door/door.fbx', (door) => {
                    door.traverse(o => {
                        if (o.isMesh) {
                            o.material.map(m => {
                                m.map = texture;
                                m.aoMap = aomap;
                                m.aoMapIntensity = 0.3;
                                m.color = new THREE.Color(0xFFFFFF);
                            });
                        }
                    });
                    doorModel = door;
                    // console.log('doorModel',doorModel)
                    scene.add(setDoors());
                });
            });
        });

        //ГРУЗИМ МОДЕЛЬ РОЗЕТОК
        new GLTFLoader().load(
            window.location.origin + allModules.filter(el => el.ID == constModels.MODEL_POWER_SOCKET_ID)[0].MODEL,
            (electricSocket) => {
                electricSocketModel = electricSocket.scene.children[0];
                scene.add(setElectricSocket());
            });
        //ГРУЗИМ МОДЕЛЬ ВЫКЛЮЧАТЕЛЬ
        new GLTFLoader().load(
            window.location.origin + allModules.filter(el => el.ID == constModels.MODEL_SWITCHER_ID)[0].MODEL,
            (switcher) => {
                switchModel = switcher.scene.children[0];
                scene.add(setSwitch());
            });
        // ГРУЗИМ МОДЕЛЬ ЭЛЕКТРОПРОВОДА
        new GLTFLoader().load(
            window.location.origin + allModules.filter(el => el.ID == constModels.MODEL_WIRING_ID)[0].MODEL,
            (electricalWire) => {
                switchModel = electricalWire.scene.children[0];
                scene.add(setOutletElectricalWire());
            });

        //ГРУЗИМ МОДЕЛЬ БАТАРЕЯ ОТОПЛЕНИЯ
        new GLTFLoader().load(
            window.location.origin + allModules.filter(el => el.ID == constModels.MODEL_RADIATOR_ID)[0].MODEL,
            (heatingBattery) => {
                heatingBatteryModel = heatingBattery.scene.children[0];
                scene.add(setHeatingBattery());
            });

        //ГРУЗИМ МОДЕЛЬ ЭЛЕКТРОЩИТА
        new GLTFLoader().load(
            window.location.origin + allModules.filter(el => el.ID == constModels.MODEL_ELECTRIC_PANEL_ID)[0].MODEL,
            (electricPanel) => {
                electricPanelModel = electricPanel.scene.children[0];
                scene.add(setElectricPanel());
            });


        function handlerMouseMove(e) {

            mouseIsOut = false;
            const rect = canvas.current.getBoundingClientRect();

            if (!mouse) mouse = new THREE.Vector2()
            if (tool !== "move") canvas.current.style.cursor = 'default';

            mouse.x = ((e.clientX - rect.left) / rect.width) * 2 - 1;
            mouse.y = -((e.clientY - rect.top) / rect.height) * 2 + 1;
        }
        canvas.current.addEventListener('mousemove', handlerMouseMove, false);

        const onClickHandler = event => {

            cameraMode === 1 && !pointer.isLocked && pointer.lock();

            if (event.type === 'touchstart') {

                if (!mouse) mouse = new THREE.Vector2()

                const rect = canvas.current.getBoundingClientRect();
                mouse.x = ((event.changedTouches[0].clientX - rect.left) / rect.width) * 2 - 1;
                mouse.y = -((event.changedTouches[0].clientY - rect.top) / rect.height) * 2 + 1;

                animate();
            }

            if (INTERSECTED) {

                let type = false, object
                if (INTERSECTED.parent.name === 'wall' && INTERSECTED.parent.planObj) {
                    type = 'wall'
                    object = INTERSECTED.parent.planObj
                } else if (INTERSECTED.name === 'floor' && INTERSECTED.planObj) {
                    type = 'floor'
                    object = INTERSECTED.planObj
                    dispatch(projectState.setLevel(type))
                } else if (INTERSECTED.name === 'roof' && INTERSECTED.planObj) {
                    type = 'roof'
                    object = INTERSECTED.planObj
                    dispatch(projectState.setLevel(type))
                } else if (INTERSECTED.parent.name === 'Modules' && INTERSECTED.planObj) {
                    type = 'module'
                    object = INTERSECTED.planObj
                }

                if (type) {
                    const clickObject = {
                        type: type,
                        object: object
                    }

                    setActiveObject(clickObject);
                    activeObject = clickObject;

                    dispatch(projectState.setModal(''));
                }
                // console.log('clickObject',clickObject)
                // console.log('activeObject',activeObject)
                // console.log('INTERSECTED',INTERSECTED)
            }

            if (event.type === 'touchstart') {
                mouse.x = -1000000;
                mouse.y = -1000000;
            }

            // raycaster.setFromCamera( mouse, camera );
            // const intersects = raycaster.intersectObjects( scene.children, true );
            // console.log('intersects',intersects)
        }
        canvas.current.addEventListener('click', onClickHandler);
        canvas.current.addEventListener('touchstart', onClickHandler);

        const handlerKeydown = ({ keyCode }) => {
            controls[keyCode] = true
        }
        const handlerKeyup = ({ keyCode }) => {
            controls[keyCode] = false
        }

        if (cameraMode === 1) {
            document.addEventListener('keydown', handlerKeydown, false);
            document.addEventListener('keyup', handlerKeyup, false);
        }

        const onMouseWheelHandler = (event) => {
            const fov = camera.fov + event.deltaY;
            if (fov < 120 && fov > 30) {
                camera.fov = fov;
                camera.updateProjectionMatrix();
            }
        }
        canvas.current.addEventListener('wheel', onMouseWheelHandler);

        const handlerGetHugeImage = (e) => {
            e.preventDefault();
            // console.log('camera.position 1',camera.position)
            const _width = canvas.current.clientWidth * 1
            const _height = canvas.current.clientHeight * 1
            const camX = camera.position.x
            const camY = camera.position.y
            const camZ = camera.position.z
            const camRX = camera.rotation.x
            const camRY = camera.rotation.y
            const camRZ = camera.rotation.z
            // console.log('_width',_width)
            // console.log('_height',_height)
            canvas.current.width = hugeImage.width * 1
            canvas.current.height = hugeImage.height * 1
            const width = canvas.current.width * 1;
            const height = canvas.current.height * 1;

            // camera.setViewOffset( width, height, 0, 0, width, height );
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
            renderer.setPixelRatio(1);
            orbitControls.update();
            renderer.render(scene, camera);

            const _hugeImage = renderer.domElement.toDataURL();
            dispatch(projectState.setHugeImage({ dataUrl: _hugeImage, width: hugeImage.width, height: hugeImage.height }))


            canvas.current.width = _width
            canvas.current.height = _height
            camera.aspect = _width / _height;
            // camera.position.set(camX,camY,camZ);
            camera.position.x = camX;
            camera.position.y = camY;
            camera.position.z = camZ;
            camera.rotation.x = camRX;
            camera.rotation.y = camRY;
            camera.rotation.z = camRZ;
            camera.updateProjectionMatrix();
            orbitControls.update();
            renderer.setSize(_width, _height);
            renderer.setPixelRatio(1);
            renderer.render(scene, camera);

            /*var desiredWidth = 4000;
            var desiredHeight = 3000;
            var stepX = 4000;
            var stepY = 3000;
            for (let y = 0; y < desiredHeight; y += stepY) {
                for (let x = 0; x < desiredWidth; x += stepX) {
                    camera.setViewOffset( desiredWidth, desiredHeight, x, y, stepX, stepY );
                    renderer.render( scene, camera );
                    const _hugeImage = renderer.domElement.toDataURL();
                    dispatch(projectState.setHugeImage(_hugeImage))
                    // console.log('screenshotDataURL',_hugeImage)
                    // saveScreenshot( "screenshot" + x + "-" + y + ".png", screenshotDataURL );
                }
            }*/

            dispatch(projectState.decPreloader())

            // console.log('camera.position 2',camera.position)
            // console.log('_width',_width)
            // console.log('_height',_height)
            // console.log('hugeImage',hugeImage)
            // console.log('canvas.current.width',canvas.current.width)
            // console.log('canvas.current.height',canvas.current.height)
            // console.log('canvas.current.clientWidth',canvas.current.clientWidth)
            // console.log('canvas.current.clientHeight',canvas.current.clientHeight)

            // onResize()

            return false;
        }
        canvas.current.addEventListener('getHugeImage', handlerGetHugeImage);

        const handlerGetCollada = (e) => {
            scene.traverse(obj => {
                if (obj.isGroup && obj.name === 'walls') {
                    obj.traverse(el => {
                        if (el.isMesh && el.parent.name !== 'wall') {
                            el.material.transparent = false;
                            el.material.opacity = 1;
                        } else if (el.isMesh && el.parent.name === 'wall') {
                            if (el.material.length > 0) {
                                el.material.map(m => {
                                    m.transparent = false;
                                    m.opacity = 1;
                                })
                            } else {
                                el.material.transparent = false;
                                el.material.opacity = 1;
                            }
                        }
                    })
                }
            });

            const exporter = new ColladaExporter();
            exporter.parse(scene, function (dae) {
                console.log('dae 1', dae);
                dispatch(projectState.decPreloader())

                const filename = "file.dae";
                const pom = document.createElement('a');
                const bb = new Blob([dae.data], { type: 'text/plain' });

                pom.setAttribute('href', window.URL.createObjectURL(bb));
                pom.setAttribute('download', filename);

                pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
                pom.draggable = true;
                pom.classList.add('dragout');

                pom.click();

                // downloadJSON( gltf );
            }, {});
        }
        canvas.current.addEventListener('getCollada', handlerGetCollada);

        const handlerGetGLTF = (e) => {
            scene.traverse(obj => {
                if (obj.isGroup && obj.name === 'walls') {
                    obj.traverse(el => {
                        if (el.isMesh && el.parent.name !== 'wall') {
                            el.material.transparent = false;
                            el.material.opacity = 1;
                        } else if (el.isMesh && el.parent.name === 'wall') {
                            if (el.material.length > 0) {
                                el.material.map(m => {
                                    m.transparent = false;
                                    m.opacity = 1;
                                })
                            } else {
                                el.material.transparent = false;
                                el.material.opacity = 1;
                            }
                        }
                    })
                }
            });

            const exporter = new GLTFExporter();
            exporter.parse(scene, function (gltf) {
                console.log('gltf', gltf);
                dispatch(projectState.decPreloader())

                // const filename = "file.gltf";
                const filename = "file.glb";
                const pom = document.createElement('a');
                const bb = new Blob([gltf], { type: 'text/plain' });

                pom.setAttribute('href', window.URL.createObjectURL(bb));
                pom.setAttribute('download', filename);

                pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
                pom.draggable = true;
                pom.classList.add('dragout');

                pom.click();
            }, { onlyVisible: false, binary: true });
        }
        canvas.current.addEventListener('getGLTF', handlerGetGLTF);

        const handlerGetOBJ = (e) => {
            scene.traverse(obj => {
                if (obj.isGroup && obj.name === 'walls') {
                    obj.traverse(el => {
                        if (el.isMesh && el.parent.name !== 'wall') {
                            el.material.transparent = false;
                            el.material.opacity = 1;
                        } else if (el.isMesh && el.parent.name === 'wall') {
                            if (el.material.length > 0) {
                                el.material.map(m => {
                                    m.transparent = false;
                                    m.opacity = 1;
                                })
                            } else {
                                el.material.transparent = false;
                                el.material.opacity = 1;
                            }
                        }
                    })
                }
            });

            const exporter = new OBJExporter();
            const result = exporter.parse(scene);
            console.log('result', result);
            dispatch(projectState.decPreloader())

            // const filename = "file.gltf";
            const filename = "file.obj";
            const pom = document.createElement('a');
            const bb = new Blob([result], { type: 'text/plain' });

            pom.setAttribute('href', window.URL.createObjectURL(bb));
            pom.setAttribute('download', filename);

            pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
            pom.draggable = true;
            pom.classList.add('dragout');

            pom.click();
        }
        canvas.current.addEventListener('getOBJ', handlerGetOBJ);


        init();
        animate();

        const handlerRedraw = (e) => {
            e.preventDefault();
            // console.log('scene',scene)
            const children_to_remove = [];
            scene.traverse(function (child) {
                if (
                    child.name == "Floor"
                    || child.name == "Roof"
                    || child.name == "Doors"
                    || child.name == "Windows"
                    || child.name == "walls"
                    || child.name == "Modules"
                ) {
                    children_to_remove.push(child);
                }
            });
            children_to_remove.forEach(function (child) {
                scene.remove(child);
            });
            generateGroups();
            return false;
        };
        const handlerUnselect = (e) => {
            e.preventDefault();
            setActiveObject(null);
            return false;
        };
        const handlerMouseOut = (e) => {
            e.preventDefault();

            mouseIsOut = true

            sendRedrawEvent(document.querySelector('#scene'));
            return false;
        };
        const handlerCenterModule = (e) => {
            e.preventDefault();
            centerModule = e.obj;
            sendRedrawEvent(document.querySelector('#scene'));
            // console.log('handlerCenterModule centerModule', centerModule)
            return false;
        };
        canvas.current.addEventListener('centerModule', handlerCenterModule, false);
        canvas.current.addEventListener('redraw', handlerRedraw, false);
        canvas.current.addEventListener('redrawSimple', handlerRedraw, false);
        canvas.current.addEventListener('unselect', handlerUnselect, false);
        canvas.current.addEventListener('mouseout', handlerMouseOut, false);

        return () => {
            window.removeEventListener("resize", onResize);
            canvas.current.removeEventListener('click', onClickHandler);
            canvas.current.removeEventListener('touchstart', onClickHandler);
            canvas.current.removeEventListener('getHugeImage', handlerGetHugeImage);
            canvas.current.removeEventListener('getCollada', handlerGetCollada);
            canvas.current.removeEventListener('getGLTF', handlerGetGLTF);
            canvas.current.removeEventListener('getOBJ', handlerGetOBJ);

            canvas.current.removeEventListener('centerModule', handlerCenterModule, false);
            canvas.current.removeEventListener('redraw', handlerRedraw, false);
            canvas.current.removeEventListener('redrawSimple', handlerRedraw, false);
            canvas.current.removeEventListener('unselect', handlerUnselect, false);

            canvas.current.removeEventListener('mousemove', handlerMouseMove, false);
            canvas.current.removeEventListener('mouseout', handlerMouseOut, false);

            if (cameraMode === 1) {
                document.removeEventListener('keydown', handlerKeydown, false);
                document.removeEventListener('keyup', handlerKeyup, false);
            }

            renderer.dispose();
            scene.remove.apply(scene, scene.children);
            cancelAnimationFrame(animate);
        }
    }, [hugeImage, modules, cameraMode, tool]);
    return <>
        <canvas ref={canvas} id='scene'></canvas>
        {activeObject !== null && (activeObject.type === 'floor' || activeObject.type === 'roof') &&
            <FloorInfo floor={activeObject.object} />
        }
        {activeObject !== null && activeObject.object.isWall &&
            <BWallInfo
                plan={plan}
                wall={activeObject.object}
            // remove={removeWall}
            // cutWall={cutWall}
            />
        }
        {activeObject !== null && (activeObject.type === 'module') &&
            <ModuleInfo3D
                module={activeObject.object}
            />
        }
    </>

};

export default ThreeScene;

function getCenterPoint(mesh) {
    var middle = new THREE.Vector3();
    var geometry = mesh.geometry;

    geometry.computeBoundingBox();

    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;

    mesh.localToWorld(middle);
    return middle;
}


function setPolygonFace(points) {
    const p1 = points[0],
        p2 = points[1],
        p3 = points[2],
        p4 = points[3];

    const vertices = [
        { pos: p1, norm: [0, 0, 1], uv: [0, 0], }, // 0
        { pos: p2, norm: [0, 0, 1], uv: [1, 0], }, // 1
        { pos: p3, norm: [0, 0, 1], uv: [0, 1], }, // 2
        { pos: p4, norm: [0, 0, 1], uv: [1, 1], }, // 3
    ];
    const positions = [];
    const normals = [];
    const uvs = [];
    for (const vertex of vertices) {
        positions.push(...vertex.pos);
        normals.push(...vertex.norm);
        uvs.push(...vertex.uv);
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(positions), 3));
    geometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(normals), 3));
    geometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uvs), 2));
    geometry.setIndex([
        0, 1, 2,
        2, 1, 3,
    ]);

    geometry.computeFaceNormals();
    geometry.computeVertexNormals();
    geometry.uvsNeedUpdate = true;

    return geometry;
}
