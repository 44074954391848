export const preloadImages = (plan) => {
    plan.icons = { rotate: null, alr: null, atb: null };
    if (!plan?.icons?.rotate) {
        const img = new Image();
        img.src = window.confComponentUrl + "assets/icons/ico-rotate.svg";
        img.onload = function () {
            plan.icons.rotate = img;
        };
    }
    if (!plan?.icons?.alr) {
        const img = new Image();
        img.src = window.confComponentUrl + "assets/icons/alr.svg";
        img.onload = function () {
            plan.icons.alr = img;
        };
    }
    if (!plan?.icons?.atb) {
        const img = new Image();
        img.src = window.confComponentUrl + "assets/icons/atb.svg";
        img.onload = function () {
            plan.icons.atb = img;
        };
    }
};
